import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import fuzzySearchFilter from 'atoms/react-table/tableFilters/fuzzySearchFilter';
import multiSelectFilter from 'atoms/react-table/tableFilters/multiSelectFilter';
import rangeFilter from 'atoms/react-table/tableFilters/rangeFilter';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { I18nContext, useT } from 'common/useT';
import { listState } from 'components/List/state';
import useSettings from 'components/Settings/useSettings';
import useUser from 'components/User/useUser';
import { BatteryNotificationType, DashboardLightMessageType, DtcEventType, TripStatisticType } from 'generated/graphql';
import { capitalize, entries } from 'utils';

import BatteryHealthCell from './cells/BatteryHealthCell';
import BatteryStatusCell from './cells/BatteryStatusCell';
import DeviceStatusCell from './cells/DeviceStatusCell';
import DriverListFleetCell from './cells/DriverListFleetCell';
import DriverNameCell from './cells/DriverNameCell';
import EcoScoreCell from './cells/EcoScoreCell';
import LicencePlateCell from './cells/LicencePlateCell';
import MilStatusCell from './cells/MilStatusCell';
import DashboardLights from './DashboardLights';
import ListDtc from './ListDtcs';
import { AggregateListRow, ListColumnId, ListItem } from 'types/settings';

export const vehicleListUniqueValueColumns: [ListColumnId, (x: ListItem) => string | null | undefined][] = [
  ['make', (x) => x.vehicle?.make],
  ['model', (x) => x.vehicle?.model],
  ['driverName', (x) => x.driver?.name],
];

export const vehicleListMinMaxValueColumns: [ListColumnId, (x: ListItem) => number | null | undefined][] = [
  ['distanceDriven', (x) => x.tripStatistics?.totalDistanceDriven ?? 0],
];

export const useListColumns = ({ useTableFiltering }: { useTableFiltering?: boolean }) => {
  const i18nContext = useContext(I18nContext);
  const { accessibleFleets } = useUser();

  const fleetList = [...accessibleFleets]
    .map((fleet) => ({
      label: fleet.name,
      value: fleet.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const {
    tSafe,
    commonTranslations: {
      general: { sort_by_text, on_text, off_text, some_text, none_text, unassigned_text },
      enums: {
        batteryNotificationTypeDescriptionMap,
        batterySohStateDescriptionMap,
        dtcEventTypeDescriptionMap,
        dashboardLightMessageTypeDescriptionMap,
        deviceConnectionStatusDescriptionMap,
        tripStatisticTypeDescriptionMap,
      },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
      },
    },
  } = useT();

  const columnHelper = createColumnHelper<AggregateListRow>();

  const { distanceInMiles, idleTimeAsPercentage, volumeInGallons } = useSettings();
  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });
  const {
    uniqueValues: { make, model },
  } = useRecoilValue(listState);

  const dedupedSortedMakes = Array.from(new Set(make?.map((x) => x.toUpperCase()))).sort();
  const dedupedSortedModels = Array.from(new Set(model?.map((x) => x.toUpperCase()))).sort();

  const someNoneFilter = (id: ListColumnId) =>
    multiSelectFilter(
      id,
      [
        { label: some_text, value: 'true' },
        { label: none_text, value: 'false' },
      ],
      { useTableFiltering },
    );

  const columns = [
    columnHelper.accessor<ListColumnId, string>('licencePlate', {
      cell: (props) => <LicencePlateCell cell={props} />,
      id: 'licencePlate',
      header: () => licencePlate_text,
      size: 120,
      meta: { filterComponent: () => fuzzySearchFilter('licencePlate') },
    }),
    columnHelper.accessor<ListColumnId, string>('make', {
      cell: (props) => props.getValue<string>()?.toUpperCase() ?? null,
      id: 'make',
      header: () => make_text,
      size: 140,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'make',
            dedupedSortedMakes.map((value) => ({
              label: value.toUpperCase(),
              value: value.toUpperCase(),
            })) ?? [],
            { useTableFiltering, searchable: true },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('model', {
      cell: (props) => <div className="capitalize">{props.getValue<string>()?.toLowerCase()}</div>,
      id: 'model',
      header: () => model_text,
      size: 140,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'model',
            dedupedSortedModels.map((value) => ({ label: capitalize(String(value)?.toLocaleLowerCase()), value })) ??
              [],
            { useTableFiltering, searchable: true },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('driverName', {
      cell: (props) => <DriverNameCell cell={props} />,
      id: 'driverName',
      header: () => driver_text,
      meta: { filterComponent: () => fuzzySearchFilter('driverName') },
    }),
    columnHelper.accessor<ListColumnId, string>('deviceStatus', {
      cell: (props) => <DeviceStatusCell cell={props} />,
      id: 'deviceStatus',
      header: () => device_connection_status_text,
      invertSorting: true,
      sortingFn: (a, b) =>
        new Date(a.original.device?.latestConnectionDate ?? 0).getTime() -
        new Date(b.original.device?.latestConnectionDate ?? 0).getTime(),
      size: 140,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'deviceStatus',
            entries(deviceConnectionStatusDescriptionMap).map(([type, description]) => ({
              label: description,
              value: type,
            })),
            { useTableFiltering },
          ),
      },
    }),

    columnHelper.accessor<ListColumnId, string[]>('fleets', {
      cell: (props) => <DriverListFleetCell cell={props} />,
      id: 'fleets',
      header: () => fleet_text,
      size: 140,
      enableSorting: false,
      meta: { filterComponent: () => multiSelectFilter('fleets', fleetList) },
    }),

    columnHelper.accessor<ListColumnId, string>('ecoScore', {
      cell: (props) => <EcoScoreCell cell={props} />,
      id: 'ecoScore',
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
      size: 140,
    }),

    columnHelper.accessor<ListColumnId, string>('milStatus', {
      cell: (props) => <MilStatusCell cell={props} />,
      id: 'milStatus',
      header: () => mil_status_text,
      size: 140,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'milStatus',
            [
              { label: on_text, value: 'true' },
              { label: off_text, value: 'false' },
            ],
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('dashboardLights', {
      cell: (props) => <DashboardLights cell={props} />,
      id: 'dashboardLights',
      header: () => activeDashboardLights_text,
      size: 170,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'dashboardLights',
            entries(dashboardLightMessageTypeDescriptionMap)
              .filter(([type]) => type !== DashboardLightMessageType.EngineMilStatus)
              .map(([type, label]) => ({
                label,
                value: type,
              })),
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('batteryCharge', {
      cell: (props) => <div className="flex-center">{props.getValue<string>()}</div>,
      id: 'batteryCharge',
      header: () => battery_voltage_text,
    }),
    columnHelper.accessor<ListColumnId, string>('batteryHealth', {
      cell: (props) => <BatteryHealthCell cell={props} />,
      id: 'batteryHealth',
      header: () => battery_health_text,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'batteryHealth',
            entries(batterySohStateDescriptionMap).map(([type, label]) => ({
              label: label,
              value: type,
            })),
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('batteryStatus', {
      cell: (props) => <BatteryStatusCell cell={props} />,
      id: 'batteryStatus',
      header: () => batteryStatus_text,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            'batteryStatus',
            entries(batteryNotificationTypeDescriptionMap)
              .filter(([type, _]) => type !== BatteryNotificationType.Drain)
              .map(([type, label]) => ({ label, value: type })),
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('dtcPending', {
      cell: (props) => <ListDtc dtcs={props.row.original.activeDtcs ?? []} dtcType={DtcEventType.Pending} />,
      id: 'dtcPending',
      header: () => dtcEventTypeDescriptionMap[DtcEventType.Pending],
      meta: {
        filterComponent: () => someNoneFilter('dtcPending'),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('dtcStored', {
      cell: (props) => <ListDtc dtcs={props.row.original.activeDtcs ?? []} dtcType={DtcEventType.Stored} />,
      id: 'dtcStored',
      header: () => dtcEventTypeDescriptionMap[DtcEventType.Stored],
      meta: {
        filterComponent: () => someNoneFilter('dtcStored'),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('dtcPermanent', {
      cell: (props) => <ListDtc dtcs={props.row.original.activeDtcs ?? []} dtcType={DtcEventType.Permanent} />,
      id: 'dtcPermanent',
      header: () => dtcEventTypeDescriptionMap[DtcEventType.Permanent],
      meta: {
        filterComponent: () => someNoneFilter('dtcPermanent'),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('distanceDriven', {
      cell: ({
        row: {
          original: { distanceDriven },
        },
      }) => (
        <div className="flex-center">
          {(distanceDriven
            ? distanceDriven * conversionRatioMap[TripStatisticType.TotalDistanceDriven]
            : undefined
          )?.toFixed(0) ?? 0}
        </div>
      ),
      id: 'distanceDriven',
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
      size: 180,
      meta: {
        filterComponent: () => rangeFilter({ id: 'distanceDriven', min: 0, max: 1200000 }),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('fuelConsumption', {
      cell: ({
        row: {
          original: { fuelConsumption },
        },
      }) => (
        <div className="flex-center">
          {(fuelConsumption
            ? fuelConsumption * conversionRatioMap[TripStatisticType.FuelConsumption]
            : undefined
          )?.toFixed(0) ?? 0}
        </div>
      ),
      id: 'fuelConsumption',
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
      size: 180,
    }),
    columnHelper.accessor<ListColumnId, string>('fuelEfficiency', {
      cell: ({
        row: {
          original: { fuelEfficiency },
        },
      }) => (
        <div className="flex-center">
          {(fuelEfficiency
            ? fuelEfficiency * conversionRatioMap[TripStatisticType.FuelEfficiency]
            : undefined
          )?.toFixed(0) ?? 0}
        </div>
      ),
      id: 'fuelEfficiency',
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
      size: 180,
    }),
    columnHelper.accessor<ListColumnId, string>('longIdlingEventCount', {
      cell: (props) => <div>{props.getValue()}</div>,
      id: 'longIdlingEventCount',
      header: () => longIdling_text,
      meta: {
        filterComponent: () => someNoneFilter('longIdlingEventCount'),
      },
    }),
    columnHelper.accessor<ListColumnId, string>('idleTime', {
      cell: (props) => <div>{props.getValue()}</div>,
      id: 'idleTime',
      header: () =>
        idleTimeAsPercentage
          ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
          : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    }),
  ];

  return columns;
};
