import { endOfDay, format, startOfDay } from 'date-fns';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import nl from 'date-fns/locale/nl';
import ro from 'date-fns/locale/ro';
import { useContext, useEffect, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import styled from 'styled-components';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { Period, UserLocale } from 'generated/graphql';
import { SelectOption } from 'types';
import { cx } from 'utils';

registerLocale('de', de);
registerLocale('nl', nl);
registerLocale('ro', ro);

export type ActivityHistoryDateFilterOption = SelectOption<keyof typeof Period | 'custom'>;

interface DateFilterProps {
  currentlySelectedPeriod: Period | undefined;
  onSelectPeriod: (item: ActivityHistoryDateFilterOption) => void;
  fromDateFilter: {
    currentValue: Date | undefined;
    onChange: (date: Date) => void;
  };
  toDateFilter: {
    currentValue: Date | undefined;
    onChange: (date: Date) => void;
  };
}

const StyledPopper = styled.div`
  background-color: #ccc;
`;

const StyledCalendar = styled.div`
  font-size: 12px;

  .react-datepicker__current-month {
    font-size: 16px;
    padding: 2px 0 6px;
  }
  .react-datepicker__navigation {
    margin: 8px 0;
  }
  .react-datepicker__day {
    width: 4rem;
    height: 4rem;
  }
  .react-datepicker__day-name {
    width: 4rem;
  }
`;

export const DateFilter = ({
  currentlySelectedPeriod: currentPeriod,
  onSelectPeriod,
  fromDateFilter,
  toDateFilter,
}: DateFilterProps) => {
  const i18nContext = useContext(I18nContext);

  const { locale } = useSettings();
  const [showCustomDateButton, setShowCustomDateButton] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    setDefaultLocale(locale);
  });

  const localeMap: Record<UserLocale, Locale> = { de, en, nl, ro };

  const onDateChange = ([startDate, endDate]: [Date, Date]) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      fromDateFilter.onChange(startOfDay(startDate));
      toDateFilter.onChange(endOfDay(endDate));
      setShowCalendar(false);
    }
  };

  const {
    tSafe,
    commonTranslations: {
      enums: { periodDescriptionMap },
    },
  } = i18nContext ?? {
    commonTranslations: {
      enums: {},
    },
  };

  const options: ActivityHistoryDateFilterOption[] =
    periodDescriptionMap && tSafe
      ? [
          { value: 'P24h', label: periodDescriptionMap[Period.P24h] },
          { value: 'P7d', label: periodDescriptionMap[Period.P7d] },
          { value: 'P30d', label: periodDescriptionMap[Period.P30d] },
          { value: 'P90d', label: periodDescriptionMap[Period.P90d] },
          {
            value: 'custom',
            label: tSafe('components.ActivityHistoryList.DateFilter.custom-date-range', {
              defaultValue: 'Custom Date Range',
            }),
          },
        ]
      : [];

  const { getProps } = useDropdownSelect(options, {
    initialItem: options.find((item) => item.value === currentPeriod) ?? options[0],
    onSelect: (item) => {
      setShowCalendar(false);
      onSelectPeriod(item);
      if (item.value === 'custom') {
        setShowCustomDateButton(true);
      } else {
        setStartDate(undefined);
        setEndDate(undefined);
        setShowCustomDateButton(false);
      }
    },
  });

  if (!tSafe) return null;

  const selectedDates =
    startDate && endDate
      ? `${format(new Date(startDate), 'eee d MMM', { locale: localeMap[locale] })} - ${format(
          new Date(endDate),
          'eee d MMM',
          {
            locale: localeMap[locale],
          },
        )}`
      : null;

  return (
    <div className="flex relative mx-1 p-1 min-w-25 bg-gray-100 rounded-8">
      <div className="flex flex-col">
        <span className="font-bold">
          {tSafe('components.ActivityHistoryList.DateFilter.date-filter', { defaultValue: 'Date Filter' })}
        </span>

        <DropdownSelect
          {...getProps()}
          overrideSelected={selectedDates}
          className={cx('min-w-20 text-md', showCustomDateButton && 'max-w-4')}
        />
      </div>

      {showCustomDateButton ? (
        <div className="flex align-baseline relative">
          <Button
            onClick={() => setShowCalendar(!showCalendar)}
            className="text-2xl absolute bottom-0.5 -right-3 hover:outline hover:bg-gray-300"
          >
            <IonIcon name="calendarOutline" />
          </Button>
        </div>
      ) : null}

      {showCalendar && (
        <div className="absolute z-600 top-6 w-35">
          {
            <DatePicker
              popperContainer={StyledPopper}
              dayClassName={() => 'text-center pt-1'}
              calendarContainer={StyledCalendar}
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              locale={locale}
              inline
              selectsRange
            />
          }
        </div>
      )}
    </div>
  );
};
