import { useContext } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { Geofence } from 'generated/graphql';
import { SelectOption } from 'types';
import { GeofenceBreachFilterType } from 'types/activityHistory';
import { entries } from 'utils';

interface GeofenceBreachFilterProps {
  geofences: Geofence[];
  onSelect: (item: SelectOption<GeofenceBreachFilterType | string>) => void;
  disabled: boolean;
}

export const GeofenceBreachFilter = ({ geofences, onSelect, disabled }: GeofenceBreachFilterProps) => {
  const i18nContext = useContext(I18nContext);

  const {
    tSafe,
    commonTranslations: {
      enums: { geofenceBreachFilterTypeDescriptionMap },
    },
  } = i18nContext ?? {
    commonTranslations: {
      enums: {},
    },
  };

  const options = geofenceBreachFilterTypeDescriptionMap
    ? entries(geofenceBreachFilterTypeDescriptionMap).map(([value, label]) => ({
        label,
        value,
      }))
    : [];

  const items = [...options, ...geofences.map((x) => ({ label: x.name, value: x.id }))];

  const { getProps } = useDropdownSelect(items, {
    initialItem: items.find((x) => x.value === GeofenceBreachFilterType.NONE),
    onSelect,
    searchable: true,
  });

  if (!tSafe) return null;

  return (
    <div className="p-1 min-w-25 bg-gray-100 rounded-8 ml-1">
      <div className="flex flex-col">
        <div className="flex items-start">
          <span className="w-full font-bold">
            {tSafe('components.ActivityHistoryList.GeofenceBreachFilter.geofence-breaches', {
              defaultValue: 'Geofence Breaches',
            })}
          </span>
        </div>

        <DropdownSelect {...getProps()} disabled={disabled} className="min-w-20 text-md" />
      </div>
    </div>
  );
};

export default GeofenceBreachFilter;
