import { CellContext } from '@tanstack/react-table';
import { AggregateListRow } from 'types/settings';

const DriverListFleetCell = ({ cell }: { cell: CellContext<AggregateListRow, string[]> }) => {
  const fleetNames = cell.getValue();
  if (!fleetNames) return null;

  return (
    <div className="w-full">
      {fleetNames.length ? fleetNames.map((id) => <div key={id}>{id}</div>) : <div>{fleetNames}</div>}
    </div>
  );
};

export default DriverListFleetCell;
