import { atom } from 'recoil';

import {
  SavedListView,
  AggregateSortByInput,
  StateAggregatorSortField,
  ListSortDirection,
  StateAggregationFilterInput,
} from 'generated/graphql';
import { ListColumnId } from 'types/settings';

type ListViewProps = {
  uniqueValues: Partial<Record<ListColumnId, NonNullable<string | null | undefined>[]>>;
  minMaxValues: Partial<Record<ListColumnId, { min: number; max: number }>>;
  activeListViewName: string;
  defaultListView: SavedListView;
  allListViews: Record<string, SavedListView>;
  onReset: () => void;
  sortBy?: AggregateSortByInput;
  selectedApiFilters?: StateAggregationFilterInput | null;
  isInEditMode?: boolean;
  tableWidth?: number;
};

/** @package */
export const listState = atom<ListViewProps>({
  key: 'listState',
  default: {
    uniqueValues: {} as Partial<Record<ListColumnId, NonNullable<string | null | undefined>[]>>,
    minMaxValues: {} as Partial<Record<ListColumnId, { min: number; max: number }>>,
    activeListViewName: 'Default list view',
    defaultListView: {} as SavedListView,
    allListViews: {} as Record<ListColumnId, SavedListView & { deletable: boolean }>,
    onReset: () => {},
    sortBy: {
      field: StateAggregatorSortField.DeviceStatus,
      direction: ListSortDirection.Asc,
    },
    selectedApiFilters: undefined,
    isInEditMode: false,
    tableWidth: 1200,
  },
});

/** @package */
export const selectedEntityIdState = atom<string | undefined>({
  key: 'selectedEntityId',
  default: undefined,
});
