import { parseISO } from 'date-fns';
import { useContext, useRef } from 'react';

import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { Pagination } from 'components/ActivityHistoryList/Pagination';
import { ActivityHistorySortType } from 'generated/graphql';
import { DriverActivityEventItem } from 'types/driverActivityHistory';
import { VehicleActivityEventItem } from 'types/vehicleActivityHistory';
import { entries } from 'utils';

import ActivityHistoryItem from './ActivityHistoryItem';

interface ActivityHistoryListProps {
  displayedEvents?: (VehicleActivityEventItem | DriverActivityEventItem)[];
  currentPageOffsetState: number[];
  setCurrentPageOffsetState: (currState: number[]) => void;
  nextPageOffset?: number;
  showPaginationForwards: boolean;
  showPaginationBack: boolean;
  sortTypeState: ActivityHistorySortType;
  dataLoading: boolean;
  filters: React.ReactNode[];
}

export const ActivityHistoryList = ({
  showPaginationForwards,
  showPaginationBack,
  displayedEvents,
  currentPageOffsetState,
  setCurrentPageOffsetState,
  nextPageOffset,
  sortTypeState,
  dataLoading,
  filters,
}: ActivityHistoryListProps) => {
  const i18nContext = useContext(I18nContext);
  const containerRef = useRef<HTMLDivElement>(null);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const onClickForwards = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

  const onClickBackwards = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  enum ActivityTimePeriod {
    Today,
    Yesterday,
    Older,
  }

  const displayedEventsByTime = displayedEvents
    ? displayedEvents.reduce((acc, curr) => {
        const eventDay = new Date(parseISO(curr.time).setHours(0, 0, 0, 0)).getTime();
        const today = new Date(Date.now()).setHours(0, 0, 0, 0);
        const oneDay = 1000 * 60 * 60 * 24;

        acc[ActivityTimePeriod.Today] ??= [];
        acc[ActivityTimePeriod.Yesterday] ??= [];
        acc[ActivityTimePeriod.Older] ??= [];

        if (eventDay === today) {
          acc[ActivityTimePeriod.Today].push(curr);
          return acc;
        }

        if (eventDay === today - oneDay) {
          acc[ActivityTimePeriod.Yesterday].push(curr);
          return acc;
        }

        acc[ActivityTimePeriod.Older].push(curr);
        return acc;
      }, {} as Record<ActivityTimePeriod, (VehicleActivityEventItem | DriverActivityEventItem)[]>)
    : [];

  interface EventGroupType {
    title: string;
    events: (VehicleActivityEventItem | DriverActivityEventItem)[];
  }

  const eventGroupMap: Record<ActivityTimePeriod, EventGroupType> = {
    [ActivityTimePeriod.Today]: {
      title: tSafe('components.ActivityHistoryList.today', { defaultValue: 'Today' }),
      events: displayedEventsByTime[ActivityTimePeriod.Today],
    },
    [ActivityTimePeriod.Yesterday]: {
      title: tSafe('components.ActivityHistoryList.yesterday', { defaultValue: 'Yesterday' }),
      events: displayedEventsByTime[ActivityTimePeriod.Yesterday],
    },
    [ActivityTimePeriod.Older]: {
      title: tSafe('components.ActivityHistoryList.older', { defaultValue: 'Older' }),
      events: displayedEventsByTime[ActivityTimePeriod.Older],
    },
  };

  const EventGroup = ({
    title,
    events,
  }: {
    title: string;
    events: (VehicleActivityEventItem | DriverActivityEventItem)[];
  }) =>
    events?.length ? (
      <>
        <div className="pl-2 py-1 w-full bg-gray-100 sticky top-0">{title}</div>

        {events.map((event, idx) => (
          <ActivityHistoryItem key={idx} event={event} />
        ))}
      </>
    ) : null;

  const eventGroups = entries(eventGroupMap);

  if (sortTypeState === ActivityHistorySortType.OldestFirst) eventGroups.reverse();

  return (
    <div className="w-full h-full flex-col text-md bg-white">
      <div className="w-full text-lg mt-1 ml-2 ">
        <h4>{tSafe('components.ActivityHistoryList.title', { defaultValue: 'Activity History' })}</h4>
      </div>

      <div className="flex align-baseline p-1 border-b-px border-gray-400">
        <div className="flex ml-auto">{filters}</div>
      </div>

      {dataLoading ? (
        <div className="w-full h-40 flex-center">
          <Spinner />
        </div>
      ) : (
        <div
          ref={containerRef}
          className="flex flex-col h-[calc(100vh-326px)] relative mb-4 bg-white overflow-y-scroll"
        >
          {displayedEvents?.length ? (
            <div>
              {eventGroups.map(([period, group]) => (
                <EventGroup key={period} title={group.title} events={group.events} />
              ))}
            </div>
          ) : (
            <span className="flex-center w-full h-full items-center">
              {tSafe('components.ActivityHistoryList.no-activity', {
                defaultValue: 'No activity found for the selected filters',
              })}
            </span>
          )}
        </div>
      )}

      {(showPaginationBack || showPaginationForwards) && (
        <div className="absolute bottom-4 right-0 px-4 flex w-100">
          <Pagination
            currentPageOffsetState={currentPageOffsetState}
            setCurrentPageOffsetState={setCurrentPageOffsetState}
            nextPageOffset={nextPageOffset ?? 0}
            showPaginationBack={showPaginationBack}
            showPaginationForwards={showPaginationForwards}
            onNext={onClickForwards}
            onPrev={onClickBackwards}
          />
        </div>
      )}
    </div>
  );
};
