import { useMutation } from '@apollo/client';
import * as Menu from '@radix-ui/react-dropdown-menu';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import Icon from 'atoms/Icon';
import FullPageTable, { sortDirectionToString, stringToSortDirection } from 'atoms/react-table/FullPageTable';
import useColumnUniqueValues from 'atoms/react-table/tableFilters/useColumnUniqueValues';
import useMinMaxValues from 'atoms/react-table/tableFilters/useMinMaxValues';
import Tooltip from 'atoms/Tooltip';
import { useT } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { useListColumns } from 'components/List/ListView/useListColumns';
import { listState } from 'components/List/state';
import useSettings from 'components/Settings/useSettings';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import {
  AggregateSortByInput,
  ApiError,
  DtcEventType,
  GetFleetIdlePerformanceDoc,
  SaveListViewSettingsDoc,
  TripStatisticType,
} from 'generated/graphql';
import { cx, entries, keys, kmToMi, reverseRecord } from 'utils';
import { getError, useQ } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';
import { ListColumnId } from 'types/settings';
import {
  columnIdToSortFieldMap,
  sortFieldMapToVehicleColumnId,
  listMinMaxValueColumns,
  listUniqueValueColumns,
} from '../utils';

const IdlingPerformance = () => {
  const [pageOffset, setPageOffset] = useState(0);
  const [listRecoilState, setListRecoilState] = useRecoilState(listState);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage, listViewSettings } = useSettings();

  const [sortBy, setSortBy] = useState<AggregateSortByInput | null>(null);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const history = useHistory();
  const maxItemsPerPage = 20;

  const {
    loading: idlePerformanceLoading,
    data: idlePerformanceData,
    refetch: refetchIdlingPerformance,
  } = useQ(GetFleetIdlePerformanceDoc, {
    variables: {
      fleetId: useCurrentFleetId(),
    },
    notifyOnNetworkStatusChange: true,
  });

  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const entities = useMemo(() => idlePerformanceData?.data ?? [], [idlePerformanceData]);

  const table = useReactTable({
    columns: useListColumns({ useTableFiltering: true }),
    data: entities.map((x) => ({
      licencePlate: x.vehicle?.licencePlate,
      make: x.vehicle?.make,
      model: x.vehicle?.model,
      driverName: x.driver?.name ?? '',
      fleets: x.user?.fleets.map((x) => x.name),
      deviceStatus: x.device?.connectionStatus,
      activeDtcs: x.activeDtcs,
      batteryCharge: x.batteryStatus?.currentState?.lastVoltage,
      batteryStatus: x.vehicleStatus?.batteryStatus,
      batteryHealth: x.batteryStatus?.stateOfHealth?.state,
      ecoScore: x.driverStatistics?.ecoScore,
      dashboardLights: x.activeDashboardLights,
      distanceDriven:
        distanceInMiles && x.driverStatistics?.totalDistanceDriven
          ? kmToMi(x.driverStatistics.totalDistanceDriven)
          : x.driverStatistics?.totalDistanceDriven,
      dtcPending: x.activeDtcs?.filter((dtc) => dtc.dtcType === DtcEventType.Pending).length,
      dtcPermanent: x.activeDtcs?.filter((dtc) => dtc.dtcType === DtcEventType.Permanent).length,
      dtcStored: x.activeDtcs?.filter((dtc) => dtc.dtcType === DtcEventType.Stored).length,
      idleTime: Math.round(
        idleTimeAsPercentage ? x.driverStatistics?.idleTimePct ?? 0 : x.driverStatistics?.idleTimeHrs ?? 0,
      ),
      longIdlingEventCount: x.driverStatistics?.numLongIdlingEvents,
      milStatus: x.vehicleStatus?.mil,
      driverId: x.driver?.id,
      vehicleId: x.vehicle?.id,
    })),
    state: {
      columnOrder: listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnOrder,
      columnVisibility: {
        ...listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnVisibility,
      },
      pagination: {
        pageIndex: pageOffset,
        pageSize: maxItemsPerPage,
      },
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const uniqueValues = useColumnUniqueValues<typeof entities[0], ListColumnId>(entities, listUniqueValueColumns);
  const minMaxValues = useMinMaxValues<typeof entities[0], ListColumnId>(entities, listMinMaxValueColumns);

  const {
    tSafe,
    commonTranslations: {
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
      },
    },
  } = useT();

  const sortFieldTextMap: Record<ListColumnId, string> = {
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driverName: driver_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleets: fleet_text,
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactStatus: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    milStatus: mil_status_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };

  useEffect(() => {
    const params = entries(Object.fromEntries(urlItems)).map(([key, value]) => ({
      key: key.toString() as ListColumnId,
      value: value.split(','),
    }));

    const filters = params.reduce((acc, { key, value }) => {
      switch (key) {
        case 'licencePlate':
          acc.push({ id: 'licencePlate', value });
          break;
        case 'make':
          acc.push({ id: 'make', value });
          break;
        case 'model':
          acc.push({ id: 'model', value });
          break;
        case 'driverName':
          acc.push({ id: 'driverName', value });
          break;
        case 'fleets':
          acc.push({ id: 'fleets', value });
          break;
        case 'dashboardLights':
          acc.push({ id: 'dashboardLights', value });
          break;
        case 'dtcPending':
          acc.push({ id: 'dtcPending', value });
          break;
        case 'dtcPermanent':
          acc.push({ id: 'dtcPermanent', value });
          break;
        case 'dtcStored':
          acc.push({ id: 'dtcStored', value });
          break;
        case 'milStatus':
          acc.push({ id: 'milStatus', value });
          break;
        case 'batteryHealth':
          acc.push({ id: 'batteryHealth', value });
          break;
        case 'batteryCharge':
          acc.push({ id: 'batteryCharge', value });
          break;
        case 'batteryStatus':
          acc.push({ id: 'batteryStatus', value });
          break;
        case 'deviceStatus':
          acc.push({ id: 'deviceStatus', value });
          break;
        case 'distanceDriven':
          acc.push({ id: 'distanceDriven', value });
          break;
        case 'longIdlingEventCount':
          acc.push({ id: 'longIdlingEventCount', value });
          break;
        default:
          break;
      }

      return acc;
    }, [] as ColumnFiltersState);

    setPageOffset(0);
    setColumnFilters(filters);
  }, [urlItems, history.location.search]);

  const [saveList] = useMutation(SaveListViewSettingsDoc, {
    onCompleted: async (newSettings) => {
      successToast(tSafe('components.ListPage.toasts.list-settings-saved', { defaultValue: 'List settings saved' }));
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  useEffect(() => {
    setListRecoilState({
      activeListViewName: listViewSettings.activeListView ?? keys(listViewSettings.defaultListView)[0],
      allListViews: listViewSettings.savedListViews,
      defaultListView: listViewSettings.defaultListView,
      uniqueValues,
      minMaxValues,
      onReset: () => {
        table.setColumnFilters([]);
      },
      tableWidth: table.getTotalSize(),
    });
  }, [listViewSettings, uniqueValues]);

  const handleColumnOrder = (order: string[]) => {
    const newListViewState = {
      ...listRecoilState.allListViews,
      [listRecoilState.activeListViewName]: {
        ...listRecoilState.allListViews[listRecoilState.activeListViewName],
        columnOrder: order,
      },
    };

    setListRecoilState({
      ...listRecoilState,
      allListViews: newListViewState,
    });

    saveList({
      variables: {
        activeListView: listRecoilState.activeListViewName,
        listViews: newListViewState,
      },
    });
  };

  return (
    <div className={cx('flex flex-col pt-0.5')}>
      <h1 className="text-md font-bold ml-1 mt-1 -mb-3">
        {tSafe('components.ListPage.idle-performance-title', {
          defaultValue: 'Bottom 10% idling performance for your fleet',
        })}
      </h1>
      <div className="py-1 w-full items-center flex justify-end border-b-px border-gray-300">
        <ResetUrlParamsToolbarItem
          table={table}
          resetFilters={() => {
            setSortBy(null);
          }}
        />

        <Menu.Root>
          <Menu.Trigger className="">
            <div className="">
              <Tooltip text="Configure columns">
                <Icon className="ui-button-dark ml-1" name="columns" />
              </Tooltip>
            </div>
          </Menu.Trigger>

          <Menu.Content
            sideOffset={7}
            align="end"
            className="relative min-w-20 bg-white h-[500px] rounded-4 shadow-card text-sm overflow-y-scroll"
          >
            <h3 className="sticky p-1 text-md border-b-px border-gray-100">
              {tSafe('components.ListPage.column-options', { defaultValue: 'Column options' })}
            </h3>

            {table.getAllLeafColumns().map((column) => {
              return (
                <label
                  key={column.id}
                  htmlFor={`${column.id}`}
                  className={cx('py-0.5 flex w-full items-center hover:bg-gray-100 cursor-pointer')}
                >
                  <input
                    {...{
                      id: column.id,
                      onChange: (e) => {
                        setListRecoilState({
                          ...listRecoilState,
                          allListViews: {
                            ...listRecoilState.allListViews,
                            [listRecoilState.activeListViewName]: {
                              ...listRecoilState.allListViews[listRecoilState.activeListViewName],
                              columnVisibility: {
                                ...listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnVisibility,
                                [column.id as ListColumnId]: e.target.checked,
                              },
                            },
                          },
                        });
                      },
                      type: 'checkbox',
                      className: 'm-1',
                      checked:
                        listRecoilState.allListViews[listRecoilState.activeListViewName!]?.columnVisibility?.[
                          column.id as ListColumnId
                        ],
                    }}
                  ></input>

                  {sortFieldTextMap[column.id as ListColumnId]}
                </label>
              );
            })}

            <Button
              onClick={() => {
                setListRecoilState({
                  ...listRecoilState,
                  allListViews: {
                    ...listRecoilState.allListViews,
                    [listRecoilState.activeListViewName!]: {
                      ...listRecoilState.allListViews[listRecoilState.activeListViewName!],
                      columnOrder: listRecoilState.defaultListView.columnOrder,
                      columnVisibility: listRecoilState.defaultListView.columnVisibility,
                    },
                  },
                });
              }}
              className="sticky p-1 border-b-px border-gray-100 hover:underline"
            >
              {tSafe('components.ListPage.restore-defaults', { defaultValue: 'Restore defaults' })}
            </Button>
          </Menu.Content>
        </Menu.Root>
      </div>
      <FullPageTable
        table={table}
        totalCount={idlePerformanceData?.count ?? 0}
        currentPage={pageOffset}
        onPageChange={(selectedPage: number) => {
          setPageOffset(selectedPage);
        }}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: columnIdToSortFieldMap[id as ListColumnId],
            direction: direction ? stringToSortDirection(direction) : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortFieldMapToVehicleColumnId[sortBy.field],
                direction: sortBy.direction ? sortDirectionToString(sortBy.direction) : undefined,
              }
            : null
        }
        setColumnOrder={handleColumnOrder}
        columnOrder={listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnOrder ?? {}}
        loading={idlePerformanceLoading}
        refetch={() => refetchIdlingPerformance()}
        useTablePagination={true}
      />
    </div>
  );
};

export default IdlingPerformance;
