import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { defaultDashboardTimePeriod } from 'common/constants';
import { driverEventsIconMap } from 'common/driverEvents';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { DriverEventType, GetDriverEventCountDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

const DriverEventCountWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetDriverEventCountDoc, {
    variables: {
      fleetId: currentFleetId,
      period: defaultDashboardTimePeriod,
    },
  });
  const history = useHistory();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        driver: { longIdlingTooltip_text },
      },
      enums: { dashboardWidgetDescriptionMap },
      general: { event_last_n_days_tfn },
    },
  } = i18nContext;

  const tiles = data?.map(({ type, description, count }) => ({
    name: type,
    description: description,
    value: count,
    icon: driverEventsIconMap[type],
    tooltip:
      type === DriverEventType.Unplug || type === DriverEventType.NotReporting
        ? undefined
        : type === DriverEventType.LongIdling
        ? longIdlingTooltip_text
        : event_last_n_days_tfn(description, 30),
    onClick: () => {
      if (type === DriverEventType.Unplug) {
        history.push(`/${currentFleetId}/list-view?deviceStatus=Disconnected`);
      }

      if (type === DriverEventType.NotReporting) {
        history.push(`/${currentFleetId}/list-view?deviceStatus=NotReporting`);
      }

      if (
        type === DriverEventType.ImpactGreen ||
        type === DriverEventType.ImpactAmber ||
        type === DriverEventType.ImpactRed
      ) {
        history.push(`/${currentFleetId}/list-view?impactStatus=true`);
      }

      if (type === DriverEventType.LongIdling) {
        history.push(`/${currentFleetId}/list-view?longIdlingEventCount=true`);
      }
    },
  }));

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.driverEventCountWidget]}
      loading={loading}
      error={error}
    >
      <WidgetTileGroup tiles={tiles ?? []} defaultIconClassName="text-error" count />
    </Widget>
  );
};

export default DriverEventCountWidget;
