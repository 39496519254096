import { format } from 'date-fns';
import { useContext } from 'react';
import { CSVLink } from 'react-csv';

import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext, useT } from 'common/useT';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import { DtcEventType, GetEntityListDoc, TripStatisticType } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';
import { useRecoilValue } from 'recoil';
import { listState } from '../state';
import { ListColumnId } from 'types/settings';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import { keys, kmToMi } from 'utils';
import IonIcon from 'atoms/IonIcon';

const ListExport = () => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();
  const listRecoilState = useRecoilValue(listState);
  const { data, loading } = useQ(GetEntityListDoc, {
    variables: {
      fleetIds: useCurrentFleetId(),
      filters: listRecoilState.selectedApiFilters,
      sortBy: listRecoilState.sortBy,
    },
    fetchPolicy: 'cache-first',
  });
  const accessibleFleetMap = useAccessibleFleetMap();

  const {
    tSafe,
    commonTranslations: {
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
      },
    },
  } = useT();

  if (!i18nContext) return null;

  const sortFieldTextMap: Record<ListColumnId, string> = {
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driverName: driver_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleets: fleet_text,
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactStatus: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    milStatus: mil_status_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };

  const headers = listRecoilState.allListViews[listRecoilState.activeListViewName].columnOrder
    .filter((x) => listRecoilState.allListViews[listRecoilState.activeListViewName].columnVisibility[x])
    .map((x) => ({ label: sortFieldTextMap[x as ListColumnId], key: x }));

  const headerKeys = new Set(headers.map((header) => header.key.toLowerCase()));

  const flatData = data?.data.map((x) => ({
    licencePlate: x.vehicle?.licencePlate,
    make: x.vehicle?.make,
    model: x.vehicle?.model,
    driverName: x.driver?.name,
    fleets: x.user?.fleets.map((x) => x.name),
    deviceStatus: x.device?.connectionStatus,
    milStatus: x.vehicleStatus?.mil,
    ecoScore: x.driverStatistics?.ecoScore,
    dashboardLights: x.activeDashboardLights,
    batteryCharge: x.batteryStatus?.currentState?.lastVoltage,
    batteryStatus: x.vehicleStatus?.batteryStatus,
    batteryHealth: x.batteryStatus?.stateOfHealth?.state,
    distanceDriven:
      distanceInMiles && x.driverStatistics?.totalDistanceDriven
        ? kmToMi(x.driverStatistics.totalDistanceDriven)
        : x.driverStatistics?.totalDistanceDriven,
    dtcPending: x.activeDtcs?.filter((dtc) => dtc.dtcType.toUpperCase() === DtcEventType.Pending).length,
    dtcPermanent: x.activeDtcs?.filter((dtc) => dtc.dtcType.toUpperCase() === DtcEventType.Permanent).length,
    dtcStored: x.activeDtcs?.filter((dtc) => dtc.dtcType.toUpperCase() === DtcEventType.Stored).length,
    fuelConsumption: x.driverStatistics?.fuelConsumption,
    fuelEfficiency: x.driverStatistics?.fuelEfficiency,
    drivingTime: x.driverStatistics?.totalTimeDriven,
    idleTime: Math.round(
      idleTimeAsPercentage ? x.driverStatistics?.idleTimePct ?? 0 : x.driverStatistics?.idleTimeHrs ?? 0,
    ),
    tripCount: x.driverStatistics?.numTrips,
    longIdlingEventCount: x.driverStatistics?.numLongIdlingEvents,
    impactStatus: x.driverStatistics?.numImpacts,
    driverId: x.driver?.id,
    vehicleId: x.vehicle?.id,
  }));

  const csvData = flatData?.map((listRow) => {
    return keys(listRow).reduce((acc, columnId) => {
      if (headerKeys.has(columnId.toLowerCase())) {
        acc[columnId] = listRow[columnId] as string;
      }
      return acc;
    }, {} as Record<ListColumnId | 'driverId' | 'vehicleId', string>);
  });

  if (!csvData || !data || loading || !accessibleFleetMap)
    return (
      <div>
        <div className="text-center text-lg mb-1">
          {tSafe('components.ListPage.exporting-list', { defaultValue: 'Exporting list...' })} <br />
        </div>
        <CenteredSpinner />;
      </div>
    );

  const fileName = `${listRecoilState.activeListViewName.replace(/,|\s|\./g, '_') ?? 'List_export'}_${format(
    new Date(),
    'PPPPp',
  ).replace(/,|\s/g, '_')}`;

  return (
    <div className="mt-2 flex-center flex-col">
      <div className="text-lg mb-1">
        {tSafe('components.ListPage.export-complete', { defaultValue: 'Export complete' })} <br />
      </div>
      <CSVLink
        data={csvData}
        headers={headers}
        filename={fileName}
        className="flex p-2 max-w-20 text-white bg-primary rounded-4"
      >
        <IonIcon className="text-lg mr-1" name="downloadOutline" />

        {tSafe('components.BulkExport.download-csv-file', { defaultValue: 'Download CSV File' })}
      </CSVLink>
    </div>
  );
};

export default ListExport;
