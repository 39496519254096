import { useMutation } from '@apollo/client';
import * as Menu from '@radix-ui/react-dropdown-menu';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import Icon from 'atoms/Icon';
import FullPageTable, { sortDirectionToString, stringToSortDirection } from 'atoms/react-table/FullPageTable';
import useColumnUniqueValues from 'atoms/react-table/tableFilters/useColumnUniqueValues';
import useMinMaxValues from 'atoms/react-table/tableFilters/useMinMaxValues';
import Tooltip from 'atoms/Tooltip';
import { useT } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { useListColumns } from 'components/List/ListView/useListColumns';
import { listState } from 'components/List/state';
import useSettings from 'components/Settings/useSettings';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import {
  AggregateSortByInput,
  ApiError,
  BatteryNotificationType,
  BatterySohState,
  DashboardLightMessageType,
  DeviceConnectionStatus,
  DtcEventType,
  EntityAssociation,
  GetEntityListDoc,
  ListSortDirection,
  MilStatus,
  SaveListViewSettingsDoc,
  StateAggregationFilterInput,
  StateAggregatorSortField,
  TripStatisticType,
} from 'generated/graphql';
import { SelectOption } from 'types';
import { cx, entries, keys, kmToMi } from 'utils';
import { getError, useQ } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';
import { ListColumnId } from 'types/settings';
import {
  columnIdToSortFieldMap,
  entityAssociationColumnMap,
  sortFieldMapToVehicleColumnId,
  listMinMaxValueColumns,
  listUniqueValueColumns,
} from '../utils';

const ListView = () => {
  const [pageOffset, setPageOffset] = useState(0);
  const [listRecoilState, setListRecoilState] = useRecoilState(listState);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage, listViewSettings } = useSettings();
  const [columnOrder, setColumnOrder] = useState(
    listViewSettings.savedListViews[listViewSettings.activeListView].columnOrder,
  );
  const [columnVisibility, setColumnVisibility] = useState(
    listViewSettings.savedListViews[listViewSettings.activeListView].columnVisibility,
  );
  const history = useHistory();
  const [selectedApiFilters, setSelectedApiFilters] = useState<StateAggregationFilterInput>();
  const [sortBy, setSortBy] = useState<AggregateSortByInput | undefined>({
    field: StateAggregatorSortField.DeviceStatus,
    direction: ListSortDirection.Asc,
  });

  const maxItemsPerPage = 20;
  const {
    register,
    setValue,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
  });

  const {
    data,
    loading,
    refetch: refetchEntityList,
  } = useQ(GetEntityListDoc, {
    variables: {
      fleetIds: useCurrentFleetId(),
      filters: selectedApiFilters,
      sortBy,
      limit: maxItemsPerPage,
      offset: pageOffset * maxItemsPerPage,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const entities = useMemo(() => data?.data ?? [], [data]);

  const table = useReactTable({
    columns: useListColumns({ useTableFiltering: false }),
    data: entities.map((x) => ({
      licencePlate: x.vehicle?.licencePlate,
      make: x.vehicle?.make,
      model: x.vehicle?.model,
      driverName: x.driver?.name ?? '',
      fleets: x.user?.fleets.map((x) => x.name),
      deviceStatus: x.device?.connectionStatus,
      activeDtcs: x.activeDtcs,
      batteryCharge: x.batteryStatus?.currentState?.lastVoltage,
      batteryStatus: x.vehicleStatus?.batteryStatus,
      batteryHealth: x.batteryStatus?.stateOfHealth?.state,
      ecoScore: x.driverStatistics?.ecoScore,
      dashboardLights: x.activeDashboardLights,
      distanceDriven:
        distanceInMiles && x.driverStatistics?.totalDistanceDriven
          ? kmToMi(x.driverStatistics.totalDistanceDriven)
          : x.driverStatistics?.totalDistanceDriven,
      fuelConsumption: x.driverStatistics?.fuelConsumption,
      fuelEfficiency: x.driverStatistics?.fuelEfficiency,
      idleTime: Math.round(
        idleTimeAsPercentage ? x.driverStatistics?.idleTimePct ?? 0 : x.driverStatistics?.idleTimeHrs ?? 0,
      ),
      longIdlingEventCount: x.driverStatistics?.numLongIdlingEvents,
      milStatus: x.vehicleStatus?.mil,
      driverId: x.driver?.id,
      vehicleId: x.vehicle?.id,
    })),
    initialState: {
      pagination: {
        pageSize: maxItemsPerPage,
      },
    },
    state: {
      columnOrder,
      columnVisibility: {
        ...columnVisibility,
        ...entityAssociationColumnMap[
          listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
        ]?.disabledColumns.reduce((a, v) => ({ ...a, [v]: false }), {}),
      },
    },
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const uniqueValues = useColumnUniqueValues<typeof entities[0], ListColumnId>(entities, listUniqueValueColumns);
  const minMaxValues = useMinMaxValues<typeof entities[0], ListColumnId>(entities, listMinMaxValueColumns);

  const {
    tSafe,
    commonTranslations: {
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
      },
    },
  } = useT();

  const assignedOptions: SelectOption<EntityAssociation>[] = [
    {
      label: tSafe('components.ListPage.assigned-options.drivers-with-vehicles', {
        defaultValue: 'Drivers with Vehicles',
      }),
      value: EntityAssociation.DriversAndVehicles,
    },
    {
      label: tSafe('components.ListPage.assigned-options.all-drivers', {
        defaultValue: 'All drivers',
      }),
      value: EntityAssociation.AllDrivers,
    },
    {
      label: tSafe('components.ListPage.assigned-options.all-vehicles', {
        defaultValue: 'All vehicles',
      }),
      value: EntityAssociation.AllVehicles,
    },
    {
      label: tSafe('components.ListPage.assigned-options.unassigned-drivers', {
        defaultValue: 'Unassigned drivers',
      }),
      value: EntityAssociation.UnassignedDrivers,
    },
    {
      label: tSafe('components.ListPage.assigned-options.unassigned-vehicles', {
        defaultValue: 'Unassigned vehicles',
      }),
      value: EntityAssociation.UnassignedVehicles,
    },
  ];

  const sortFieldTextMap: Record<ListColumnId, string> = {
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driverName: driver_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleets: fleet_text,
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactStatus: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    milStatus: mil_status_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };

  useEffect(() => {
    if (!urlItems) {
      setSelectedApiFilters(undefined);
      return;
    }

    const params = entries(Object.fromEntries(urlItems)).map(([key, value]) => ({
      key: key.toString() as ListColumnId,
      value: value.split(','),
    }));

    const filters = params.reduce((acc, { key, value }) => {
      switch (key) {
        case 'licencePlate':
          acc.licencePlates = [...(acc.licencePlates ?? []), ...value];
          break;
        case 'make':
          acc.vehicleMakes = [...(acc.vehicleMakes ?? []), ...value];
          break;
        case 'model':
          acc.vehicleModels = [...(acc.vehicleModels ?? []), ...value];
          break;
        case 'driverName':
          acc.driverNames = [...(acc.driverNames ?? []), ...value];
          break;
        case 'fleets':
          acc.fleetIds = [...(acc.fleetIds ?? []), ...value];
          break;
        case 'deviceStatus':
          acc.deviceConnectedStatusIncludes = [
            ...(acc.deviceConnectedStatusIncludes ?? []),
            ...(value as DeviceConnectionStatus[]),
          ];
          break;
        case 'milStatus':
          acc.milStatus = value.includes('true') ? MilStatus.On : MilStatus.Off;
          break;
        case 'dashboardLights':
          acc.dashboardLightsIncludes = [
            ...(acc.dashboardLightsIncludes ?? []),
            ...(value as DashboardLightMessageType[]),
          ];
          break;
        case 'dtcPending':
          if (value.includes('true'))
            acc.dtcsIncludeType = Array.from(
              new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Pending]),
            ) as DtcEventType[];
          break;
        case 'dtcStored':
          if (value.includes('true'))
            acc.dtcsIncludeType = Array.from(
              new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Stored]),
            ) as DtcEventType[];
          break;
        case 'dtcPermanent':
          if (value.includes('true'))
            acc.dtcsIncludeType = Array.from(
              new Set([...(acc.dtcsIncludeType ?? []), DtcEventType.Permanent]),
            ) as DtcEventType[];
          break;

        case 'batteryHealth':
          acc.batterySohsIncludes = [...(acc.batterySohsIncludes ?? []), ...(value as BatterySohState[])];
          break;
        case 'batteryStatus':
          acc.batteryNotificationTypesIncludes = [
            ...(acc.batteryNotificationTypesIncludes ?? []),
            ...(value as BatteryNotificationType[]),
          ];
          break;

        case 'distanceDriven':
          const [min, max] = value;
          acc.distanceDrivenFloor = parseInt(min);
          acc.distanceDrivenCeiling = parseInt(max);
          break;
        case 'longIdlingEventCount':
          acc.driverHasLongIdlingEvents = true;
          break;
        default:
          break;
      }

      return acc;
    }, {} as StateAggregationFilterInput);

    setSelectedApiFilters(filters);
    setPageOffset(0);
  }, [urlItems, history.location.search]);

  const [saveList, { loading: savingList }] = useMutation(SaveListViewSettingsDoc, {
    onCompleted: async () => {
      successToast(tSafe('components.ListPage.toasts.list-settings-saved', { defaultValue: 'List settings saved' }));
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  // Update Recoil State
  useEffect(() => {
    setListRecoilState({
      activeListViewName: listViewSettings.activeListView ?? keys(listViewSettings.defaultListView)[0],
      allListViews: {
        ...listRecoilState.allListViews,
        [listRecoilState.activeListViewName]: {
          ...listRecoilState.allListViews[listRecoilState.activeListViewName],
          columnOrder,
          columnVisibility,
        },
      },
      defaultListView: listViewSettings.defaultListView,
      selectedApiFilters,
      sortBy,
      uniqueValues,
      minMaxValues,
      onReset: () => {
        table.setColumnFilters([]);
      },
      tableWidth: table.getTotalSize(),
    });
  }, [columnOrder, columnVisibility, selectedApiFilters, sortBy, uniqueValues]);

  const handleColumnOrder = (order: string[]) => {
    setColumnOrder(order);
    saveList({
      variables: {
        activeListView: listRecoilState.activeListViewName,
        listViews: {
          ...listRecoilState.allListViews,
          [listRecoilState.activeListViewName]: {
            ...listRecoilState.allListViews[listRecoilState.activeListViewName],
            columnOrder: order,
          },
        },
      },
    });
  };
  const { getProps } = useDropdownSelect(assignedOptions, {
    onSelect: (item: SelectOption<EntityAssociation>) => {
      const newListState = {
        ...listRecoilState.allListViews,
        [listRecoilState.activeListViewName]: {
          ...listRecoilState.allListViews[listRecoilState.activeListViewName],
          entityAssociation: item.value,
        },
      };
      setListRecoilState({
        ...listRecoilState,
        allListViews: newListState,
      });
      setSelectedApiFilters({ ...selectedApiFilters, entityAssociation: item.value });
      saveList({
        variables: {
          activeListView: listRecoilState.activeListViewName,
          listViews: newListState,
        },
      });
    },
  });

  return (
    <div className={cx('flex flex-col pt-0.5')}>
      <div className="py-1 w-full items-center flex justify-end border-b-px border-gray-300">
        <ResetUrlParamsToolbarItem
          table={table}
          resetFilters={() => {
            setSortBy(undefined);
            setSelectedApiFilters(undefined);
          }}
        />

        <div className="flex space-x-1 ml-1">
          <DropdownSelect
            innerWrapperClassName="w-[200px]"
            {...getProps()}
            selectedItem={
              assignedOptions.find(
                (x) => x.value === listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation,
              ) ?? assignedOptions[0]
            }
            className="z-600"
          />
        </div>
        <Menu.Root
          onOpenChange={(open) => {
            if (!open && isDirty) {
              saveList({
                variables: {
                  activeListView: listRecoilState.activeListViewName,
                  listViews: listRecoilState.allListViews,
                },
              });
            }
          }}
        >
          <Menu.Trigger className="">
            <div className="">
              <Tooltip text="Configure columns">
                <Icon className="ui-button-dark ml-1" name="columns" />
              </Tooltip>
            </div>
          </Menu.Trigger>

          <Menu.Content
            sideOffset={7}
            align="end"
            className="relative min-w-20 bg-white h-[500px] rounded-4 shadow-card text-sm overflow-y-scroll"
          >
            <h3 className="sticky p-1 text-md border-b-px border-gray-100">
              {tSafe('components.ListPage.column-options', { defaultValue: 'Column options' })}
            </h3>

            {table
              .getAllLeafColumns()
              .filter(
                (x) =>
                  !entityAssociationColumnMap[
                    listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
                  ]?.disabledColumns.includes(x.id as ListColumnId),
              )
              .map((column) => {
                return (
                  <label
                    key={column.id}
                    htmlFor={`${column.id}`}
                    className={cx(
                      'py-0.5 flex w-full items-center hover:bg-gray-100 cursor-pointer',
                      entityAssociationColumnMap[
                        listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
                      ]?.enforcedColumns?.includes(column.id as ListColumnId) && 'text-gray-300 cursor-not-allowed',
                    )}
                  >
                    <input
                      {...register(column.id)}
                      {...{
                        id: column.id,
                        onChange: (e) => {
                          setColumnVisibility({
                            ...listRecoilState.allListViews[listRecoilState.activeListViewName]?.columnVisibility,
                            [column.id as ListColumnId]: e.target.checked,
                          });
                        },
                        type: 'checkbox',
                        className: 'm-1',
                        disabled: entityAssociationColumnMap[
                          listRecoilState.allListViews[listRecoilState.activeListViewName]?.entityAssociation
                        ]?.enforcedColumns?.includes(column.id as ListColumnId),
                        checked: columnVisibility?.[column.id as ListColumnId],
                      }}
                    ></input>

                    {sortFieldTextMap[column.id as ListColumnId]}
                  </label>
                );
              })}

            <Button
              onClick={() => {
                entries(listRecoilState.defaultListView.columnVisibility).forEach(([key, value]) =>
                  setValue(key as string, value, { shouldDirty: true }),
                );
                setColumnOrder(listRecoilState.defaultListView.columnOrder);
                setColumnVisibility(listRecoilState.defaultListView.columnVisibility);
              }}
              className="sticky p-1 border-b-px border-gray-100 hover:underline"
            >
              {tSafe('components.ListPage.restore-defaults', { defaultValue: 'Restore defaults' })}
            </Button>
          </Menu.Content>
        </Menu.Root>
      </div>

      <FullPageTable
        table={table}
        totalCount={data?.count}
        currentPage={pageOffset}
        onPageChange={(selectedPage: number) => {
          setPageOffset(selectedPage);
        }}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: columnIdToSortFieldMap[id as ListColumnId],
            direction: direction ? stringToSortDirection(direction) : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortFieldMapToVehicleColumnId[sortBy.field],
                direction: sortBy.direction ? sortDirectionToString(sortBy.direction) : undefined,
              }
            : null
        }
        setColumnOrder={handleColumnOrder}
        columnOrder={columnOrder}
        loading={loading}
        refetch={() => refetchEntityList()}
      />
    </div>
  );
};

export default ListView;
