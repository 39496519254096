import { format, parseISO } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { batteryNotificationIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import {
  DateTimeColumn,
  DefaultAdditionalColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
} from 'components/ActivityHistoryList/styledComponents';
import { BatteryNotificationEvent } from 'generated/graphql';
import { cx } from 'utils';

interface BatteryEventRowItemProps {
  event: Partial<BatteryNotificationEvent>;
  time: string;
}

export const BatteryEventRowItem = ({ event, time }: BatteryEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);
  const { notificationType, voltage } = event;

  if (!notificationType || !i18nContext) return null;

  const {
    commonTranslations: {
      enums: { batteryNotificationTypeDescriptionMap },
      domain: {
        battery: { battery_text },
      },
    },
  } = i18nContext;

  return (
    <EventDetailContainer>
      <DateTimeColumn>{format(parseISO(time), 'iii PPp')}</DateTimeColumn>

      <EventTypeColumn>{battery_text}</EventTypeColumn>

      <IconColumn>
        <Icon
          name={batteryNotificationIconMap[notificationType!][0]}
          className={cx('!w-3', batteryNotificationIconMap[notificationType!][1])}
        />
      </IconColumn>

      <DefaultAdditionalColumn>{batteryNotificationTypeDescriptionMap[notificationType]}</DefaultAdditionalColumn>

      <DefaultAdditionalColumn>{voltage} v</DefaultAdditionalColumn>
    </EventDetailContainer>
  );
};
