import { useContext, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { periodStartToDate } from 'common/periodHelpers';
import { I18nContext } from 'common/useT';
import { ActivityHistoryList } from 'components/ActivityHistoryList';
import { ActivityHistoryDateFilterOption, DateFilter } from 'components/ActivityHistoryList/DateFilter';
import { EventTypeFilter } from 'components/ActivityHistoryList/EventTypeFilter';
import { activityHistoryTripIds } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import GeofenceBreachFilter from 'components/ActivityHistoryList/GeofenceBreachFilter';
import { ListSort } from 'components/ActivityHistoryList/ListSort';
import { useCurrentVehicleId, useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import {
  ActivityHistorySortType,
  GetActivityHistoryDoc,
  GetFleetGeofencesDoc,
  Period,
  VehicleActivityHistoryIncludeType,
  VehicleGeofenceBreachEvent,
  VehicleTripEvent,
} from 'generated/graphql';
import { SelectOption } from 'types';
import { GeofenceBreachFilterType } from 'types/activityHistory';
import { VehicleActivityEventItem, VehicleActivityEventType } from 'types/vehicleActivityHistory';
import { arrayDedupeOnKey, values, groupByKeyToMap, isDefined } from 'utils';
import { useQ } from 'utils/apolloClient';

const maxItemsPerPage = 100;
const defaultPeriod = Period.P7d;

const eventTypeToIncludeTypeMap: Record<VehicleActivityEventType, VehicleActivityHistoryIncludeType> = {
  battery: VehicleActivityHistoryIncludeType.BatteryNotificationEvents,
  dashboard_light: VehicleActivityHistoryIncludeType.DashboardLightEvents,
  disconnected: VehicleActivityHistoryIncludeType.DeviceDisconnectEvents,
  reconnected: VehicleActivityHistoryIncludeType.DeviceReconnectEvents,
  dtc: VehicleActivityHistoryIncludeType.DeviceReconnectEvents,
  impact: VehicleActivityHistoryIncludeType.ImpactEvents,
  trip: VehicleActivityHistoryIncludeType.TripEvents,
};

const getGeofenceIdFilter = (state: GeofenceBreachFilterType | string, allGeofenceIds: string[]) => {
  if (state === GeofenceBreachFilterType.NONE) return undefined;
  if (state === GeofenceBreachFilterType.ALL) return allGeofenceIds;

  return [state];
};

export const ActivityHistoryPanel = () => {
  const i18nContext = useContext(I18nContext);
  const setTripIds = useSetRecoilState(activityHistoryTripIds);
  const defaultDateFrom = periodStartToDate(defaultPeriod);
  const [eventTypeFilterState, setEventTypeFilterState] = useState(values(VehicleActivityEventType));
  const [periodFilterState, setPeriodFilterState] = useState<Period | undefined>(defaultPeriod);
  const [dateFromFilterState, setDateFromFilterState] = useState<Date | undefined>(defaultDateFrom);
  const [dateToFilterState, setDateToFilterState] = useState<Date | undefined>(new Date());
  const [sortTypeState, setSortTypeState] = useState(ActivityHistorySortType.NewestFirst);
  const [geofenceBreachFilterState, setGeofenceBreachFilterState] = useState<GeofenceBreachFilterType | string>(
    GeofenceBreachFilterType.NONE,
  );
  const [currentPageOffsetState, setCurrentPageOffsetState] = useState<number[]>([]);
  const resetPageOffsets = () => setCurrentPageOffsetState([]);
  const currentPageOffset = currentPageOffsetState.reduce((a, b) => a + b, 0);
  const vehicleId = useCurrentVehicleId();

  const { loading: vehicleDetailsLoading, data: [vehicleDetails] = [] } = useVehicleDetails();
  const { data: fleetGeofences, loading: fleetGeofencesLoading } = useQ(GetFleetGeofencesDoc, {
    skip: !vehicleDetails?.associations.fleetIds.length,
    variables: {
      fleetId: vehicleDetails?.associations.fleetIds[0],
      withSubfleets: false,
      withParentFleets: true,
    },
  });

  const dedupedGeofences = useMemo(() => {
    if (fleetGeofences && vehicleDetails) {
      const { geofences } = vehicleDetails;
      return arrayDedupeOnKey([...fleetGeofences, ...geofences], 'id');
    }
    return [];
  }, [fleetGeofences, vehicleDetails]);

  const {
    loading,
    error,
    data: [activityHistory] = [],
  } = useQ(GetActivityHistoryDoc, {
    variables: {
      vehicleId: vehicleId!,
      offset: currentPageOffset,
      maxRecords: maxItemsPerPage,
      fromDate: dateFromFilterState?.toISOString(),
      toDate: dateToFilterState?.toISOString(),
      sortType: sortTypeState,
      include: eventTypeFilterState.length ? eventTypeFilterState.map((x) => eventTypeToIncludeTypeMap[x]) : undefined,
      geofenceIds: getGeofenceIdFilter(
        geofenceBreachFilterState,
        dedupedGeofences.map((x) => x.id),
      ),
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setTripIds(
      activityHistory?.activity.data
        .filter((x) => x.__typename.includes('TripEvent'))
        ?.map((x) => x.messageId)
        .reverse(),
    );
  }, [activityHistory?.activity, setTripIds]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  const onDateFilterSelectPeriod = (item: ActivityHistoryDateFilterOption) => {
    if (item.value === 'custom') {
      setPeriodFilterState(undefined);
      resetPageOffsets();
      return;
    }
    const period = item.value as Period;
    setPeriodFilterState(period);
    setDateFromFilterState(periodStartToDate(period));
    setDateToFilterState(new Date());
    resetPageOffsets();
  };

  const onSelectGeofenceFilter = (item: SelectOption<GeofenceBreachFilterType | string>) => {
    setGeofenceBreachFilterState(item.value);
    resetPageOffsets();
  };

  if (error) return <div className="h-full m-2 text-lg">{error_text}</div>;

  if (
    loading ||
    vehicleDetailsLoading ||
    fleetGeofencesLoading ||
    !vehicleDetails ||
    !fleetGeofences ||
    !activityHistory
  )
    return (
      <ActivityHistoryList
        currentPageOffsetState={currentPageOffsetState}
        setCurrentPageOffsetState={setCurrentPageOffsetState}
        showPaginationBack={false}
        showPaginationForwards={false}
        sortTypeState={sortTypeState}
        dataLoading={loading}
        filters={[
          <ListSort key="sort" currentSortType={sortTypeState} onSelect={(item) => setSortTypeState(item.value)} />,
          <DateFilter
            key="date_filter"
            currentlySelectedPeriod={periodFilterState}
            onSelectPeriod={onDateFilterSelectPeriod}
            fromDateFilter={{
              currentValue: dateFromFilterState,
              onChange: (dateFrom) => {
                setDateFromFilterState(dateFrom);
                resetPageOffsets();
              },
            }}
            toDateFilter={{
              currentValue: dateToFilterState,
              onChange: (dateTo) => {
                setDateToFilterState(dateTo);
                resetPageOffsets();
              },
            }}
          />,
          <EventTypeFilter
            key="event_type_filter"
            eventTypes={values(VehicleActivityEventType)}
            currentlySelectedEventTypes={eventTypeFilterState}
            setEventTypeItems={(items) => setEventTypeFilterState(items)}
          />,
          <GeofenceBreachFilter
            key="geofence_filter"
            disabled={!eventTypeFilterState.includes(VehicleActivityEventType.TRIP)}
            geofences={[]}
            onSelect={onSelectGeofenceFilter}
          />,
        ]}
      />
    );

  const {
    activity: { count, data: activityHistoryData },
  } = activityHistory;

  const geofenceBreachMap: Record<string, VehicleGeofenceBreachEvent[]> = groupByKeyToMap(
    activityHistoryData.filter((x) => x.__typename.includes('GeofenceBreachEvent')) as VehicleGeofenceBreachEvent[],
    'messageId',
  );

  const allEvents: VehicleActivityEventItem[] = activityHistoryData
    .map((event) => {
      switch (event.__typename) {
        case 'VehicleTripEvent': {
          return {
            ...event,
            geofenceNotifications: geofenceBreachMap[event.messageId],
          } as Partial<VehicleTripEvent> & { geofenceNotifications: Partial<VehicleGeofenceBreachEvent>[] };
        }
        case 'VehicleGeofenceBreachEvent': {
          return undefined;
        }
        default: {
          return event;
        }
      }
    })
    .filter(isDefined);

  return (
    <>
      <ActivityHistoryList
        currentPageOffsetState={currentPageOffsetState}
        setCurrentPageOffsetState={setCurrentPageOffsetState}
        showPaginationForwards={count > currentPageOffset + maxItemsPerPage}
        showPaginationBack={currentPageOffsetState.length > 0}
        displayedEvents={allEvents}
        nextPageOffset={currentPageOffset + maxItemsPerPage}
        sortTypeState={sortTypeState}
        dataLoading={loading}
        filters={[
          <ListSort key="sort" currentSortType={sortTypeState} onSelect={(item) => setSortTypeState(item.value)} />,
          <DateFilter
            key="date_filter"
            currentlySelectedPeriod={periodFilterState}
            onSelectPeriod={onDateFilterSelectPeriod}
            fromDateFilter={{
              currentValue: dateFromFilterState,
              onChange: (dateFrom) => {
                setDateFromFilterState(dateFrom);
                resetPageOffsets();
              },
            }}
            toDateFilter={{
              currentValue: dateToFilterState,
              onChange: (dateTo) => {
                setDateToFilterState(dateTo);
                resetPageOffsets();
              },
            }}
          />,
          <EventTypeFilter
            key="event_type_filter"
            eventTypes={values(VehicleActivityEventType)}
            currentlySelectedEventTypes={eventTypeFilterState}
            setEventTypeItems={(items) => setEventTypeFilterState(items)}
          />,
          <GeofenceBreachFilter
            key="geofence_filter"
            disabled={!eventTypeFilterState.includes(VehicleActivityEventType.TRIP)}
            geofences={dedupedGeofences}
            onSelect={onSelectGeofenceFilter}
          />,
        ]}
      />
    </>
  );
};
