import { useMutation } from '@apollo/client';
import { DetailedHTMLProps, HTMLAttributes, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import { useModal } from 'atoms/Modal';
import ConfirmModal from 'atoms/Modal/ConfirmModal';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { DriverEditFormData } from 'components/Driver/Detail/Edit/DriverEditForm';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { listState } from 'components/List/state';
import { ApiError, SaveListViewSettingsDoc } from 'generated/graphql';
import { keys } from 'utils';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

const Trigger = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div {...props}>
      <span className="text-sm space-x-0.5 flex-center ui-button-dark">
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>{tSafe('components.ListPage.new-list', { defaultValue: 'New List' })}</span>
      </span>
    </div>
  );
};

const SaveListViewToolbarItem = ({
  isInEditMode = false,
  listNameToEdit,
}: {
  isInEditMode?: boolean;
  listNameToEdit?: string;
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { isValid, dirtyFields, isDirty, errors },
  } = useForm({
    defaultValues: { name: isInEditMode ? listNameToEdit ?? '' : '' },
    mode: 'onSubmit',
  });

  const [saveList, { loading }] = useMutation(SaveListViewSettingsDoc, {
    onCompleted: async (newSettings) => {
      successToast(
        isInEditMode
          ? tSafe('components.ListPage.toasts.list-name-edited', { defaultValue: 'List name edited' })
          : tSafe('components.ListPage.toasts.saved-new-list-view', { defaultValue: 'Saved new list view' }),
      );
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  const editViewName = (name: string, newName: string) => {
    const newViews = { ...listRecoilState.allListViews };
    newViews[newName] = newViews[name];
    delete newViews[name];

    setListRecoilState({
      ...listRecoilState,
      activeListViewName: newName,
      allListViews: newViews,
    });

    saveList({ variables: { activeListView: newName, listViews: newViews } });
  };
  const i18nContext = useContext(I18nContext);
  const { closeModal, props } = useModal({});
  const [listRecoilState, setListRecoilState] = useRecoilState(listState);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        user: {
          fields: { name_text },
        },
      },
    },
  } = i18nContext;

  return (
    <ConfirmModal
      confirmYesButtonClassName="rounded-4 px-1 py-0.5 bg-primary text-white"
      onConfirm={() =>
        handleSubmit(() => {
          if (isInEditMode && listNameToEdit) {
            editViewName(listNameToEdit, getValues().name);
          } else {
            if (keys(listRecoilState.allListViews).includes(getValues().name)) {
              alert('List name exists!');
            } else {
              setListRecoilState({
                ...listRecoilState,
                activeListViewName: getValues().name,
                allListViews: {
                  ...listRecoilState.allListViews,
                  [getValues().name]: {
                    ...listRecoilState.allListViews[listRecoilState.activeListViewName],
                    default: false,
                  },
                },
              });
              saveList({
                variables: {
                  activeListView: getValues().name,
                  listViews: {
                    ...listRecoilState.allListViews,
                    [getValues().name]: {
                      ...listRecoilState.allListViews[listRecoilState.activeListViewName],
                      default: false,
                    },
                  },
                },
              });
            }
          }
        })()
      }
      modalProps={{
        ...props,
        title: isInEditMode
          ? tSafe('components.ListPage.edit-list-name', { defaultValue: 'Edit list name' })
          : tSafe('components.ListPage.create-new-list-view', { defaultValue: 'Create new list view' }),
        trigger: isInEditMode ? (
          <div className="px-1 py-2 hover:bg-gray-100">
            <IonIcon name="pencilOutline" />
          </div>
        ) : (
          <Trigger />
        ),
      }}
      confirmContent={
        <div className="text-md">
          {loading && (
            <div className="flex flex-col">
              <CenteredSpinner />
            </div>
          )}

          <span className="mr-1">{name_text}:</span>

          <input
            {...register('name', { required: true })}
            className="min-w-[400px] outline outline-gray-300 rounded-4 px-2 py-1 placeholder-gray-300"
            defaultValue={listRecoilState.activeListViewName}
          />
        </div>
      }
    />
  );
};

export default SaveListViewToolbarItem;
