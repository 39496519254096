import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  useSensor,
  PointerSensor,
  useSensors,
  pointerWithin,
} from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, flexRender } from '@tanstack/react-table';
import React, { useContext, useRef, useState } from 'react';

import Button from 'atoms/Button';
import { ConditionalWrapper } from 'atoms/ConditionalWrapper';
import { Draggable } from 'atoms/Draggable';
import { Droppable } from 'atoms/Droppable';
import Icon from 'atoms/Icon';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { useT } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { DtcEventType, ListSortDirection, TripStatisticType } from 'generated/graphql';
import { createTableContext, cx } from 'utils';

import PageSelector from './PageSelector';
import { ListColumnId, UserListColumnId } from 'types/settings';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends unknown, TValue> {
    filterComponent: (props: string | string[]) => JSX.Element;
  }
}

export const stringToSortDirection = (direction: string): ListSortDirection => {
  if (direction === 'desc') return ListSortDirection.Desc;

  return ListSortDirection.Asc;
};

export const sortDirectionToString = (direction: ListSortDirection): string => {
  if (direction === ListSortDirection.Desc) return 'desc';

  return 'asc';
};

const FullPageTableContext = createTableContext<any>();
export const useFullPageTableContext = () => useContext(FullPageTableContext);

interface FullPageTableProps<T extends object> {
  table: Table<T>;
  totalCount?: number;
  currentPage?: number;
  onPageChange?: (selected: number) => void;
  toggleSortBy?: (id?: string, direction?: string | null) => void;
  sortedColumn?: { field: string; direction?: string } | null;
  columnOrder?: string[] | false;
  setColumnOrder?: (newOrder: string[]) => void;
  useTablePagination?: boolean;
  loading?: boolean;
  refetch?: () => void;
}

const FullPageTable = <T extends object>({
  table,
  totalCount,
  currentPage,
  onPageChange,
  toggleSortBy,
  sortedColumn,
  columnOrder,
  setColumnOrder,
  useTablePagination = false,
  loading = false,
  refetch,
}: FullPageTableProps<T>) => {
  const { getHeaderGroups, getRow } = table;
  const listRef = useRef<HTMLDivElement>(null);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();

  const toggleSortByDirection = (columnId: string) => {
    if (!toggleSortBy) return;

    const newDirection =
      sortedColumn?.field === columnId
        ? sortedColumn.direction === sortDirectionToString(ListSortDirection.Asc)
          ? sortDirectionToString(ListSortDirection.Desc)
          : !sortedColumn.direction
          ? sortDirectionToString(ListSortDirection.Asc)
          : null
        : sortDirectionToString(ListSortDirection.Asc);

    toggleSortBy(columnId, newDirection);
  };

  const [pageOffset, setPageOffset] = useState(0);
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }));

  const {
    tSafe,
    commonTranslations: {
      general: { sort_by_text },
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },

        user: {
          fields: { active_text, email_text, name_text, role_text, actions_text },
        },
      },
      pagination: { count_records_tfn },
    },
  } = useT();

  const sortFieldTextMap: Record<ListColumnId | UserListColumnId, string> = {
    active: active_text,
    actions: actions_text,
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driver: driver_text,
    driverName: driver_text,
    email: email_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleets: fleet_text,
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactStatus: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    name: name_text,
    milStatus: mil_status_text,
    role: role_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };
  const [movingColumnId, setMovingColumnId] = useState<ListColumnId | null>(null);
  const [targetColumnId, setTargetColumnId] = useState<ListColumnId | null>(null);

  const reorderColumn = (movingColumnId: ListColumnId, targetColumnId: ListColumnId): ListColumnId[] => {
    if (!columnOrder) return [];

    const newColumnOrder = [...columnOrder] as ListColumnId[];

    newColumnOrder.splice(
      newColumnOrder.indexOf(targetColumnId),
      0,
      newColumnOrder.splice(newColumnOrder.indexOf(movingColumnId), 1)[0],
    );
    return newColumnOrder;
  };

  const handleDragEnd = (e: DragEndEvent) => {
    setMovingColumnId(null);

    if (movingColumnId && targetColumnId && setColumnOrder)
      setColumnOrder(reorderColumn(movingColumnId, targetColumnId));
  };

  const maxItemsPerPage = 20;
  const viewedItemRange = `${table.getRowModel().rows.length === 0 ? 0 : pageOffset * maxItemsPerPage + 1} - ${
    pageOffset * maxItemsPerPage + table.getRowModel().rows.length
  }`;

  const Headers = getHeaderGroups().map((group) => (
    <thead key={group.id} className="sticky top-0 bg-gray-100 pb-0.5 shadow-[0_4px_6px_-4px_rgba(0,0,0,0.2)] z-500">
      <tr>
        {group.headers.map((column, index) => (
          <th
            className={cx(
              'align-bottom text-sm font-normal',
              movingColumnId === column.id ? 'opacity-20' : 'opacity-100',
            )}
            key={`${column}-${index}`}
          >
            <ConditionalWrapper
              condition={!!columnOrder}
              wrapper={({ children }: { children: JSX.Element }) => <Draggable id={column.id}>{children}</Draggable>}
            >
              <>
                <div
                  className={cx(
                    'group flex py-0.5 w-full min-w-16 transition-all hover:bg-gray-300',
                    column.id === sortedColumn?.field && sortedColumn.direction != null && 'bg-gray-300',
                  )}
                  style={{ width: column.getSize() }}
                >
                  {columnOrder && (
                    <Icon name="dots" width={10} className="hidden group-hover:block hover:cursor-grab" />
                  )}

                  <ConditionalWrapper
                    condition={column.getContext().column.getCanSort()}
                    wrapper={({ children }: { children: JSX.Element }) => (
                      <Tooltip text={`${sort_by_text} ${sortFieldTextMap[column.id as ListColumnId]}`}>
                        {children}
                      </Tooltip>
                    )}
                  >
                    <span
                      className={cx(
                        column.id === sortedColumn?.field && sortedColumn.direction != null && 'font-bold',
                        'flex text-left pl-[3px] capitalize',
                      )}
                      onClick={() => {
                        if (column.getContext().column.getCanSort()) {
                          toggleSortByDirection(column.id);
                          if (useTablePagination) column.getContext().column.toggleSorting();
                        }
                      }}
                    >
                      {flexRender(column.column.columnDef.header, column.getContext())}

                      {column.getContext().column.getCanSort() ? (
                        <div className="inline ml-0.5 pt-[1px] text-[10px]">
                          {column.getContext().column.getIsSorted() || sortedColumn?.field === column.id ? (
                            <div>
                              {sortedColumn?.direction === sortDirectionToString(ListSortDirection.Desc) ? (
                                <div className="group-hover:invisible">
                                  <IonIcon name="arrowUp" className={cx('text-md group-hover:rotate-180')} />
                                </div>
                              ) : (
                                sortedColumn?.direction === sortDirectionToString(ListSortDirection.Asc) && (
                                  <div className="group-hover:rotate-180 transition-all">
                                    <IonIcon name="arrowDown" className={cx('text-md')} />
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="invisible group-hover:visible">
                              <IonIcon name="arrowDown" className={cx('text-md group-hover:rotate-180')} />
                            </div>
                          )}
                        </div>
                      ) : null}
                    </span>
                  </ConditionalWrapper>
                </div>

                <div className="h-3 mb-[2px]">
                  {column.column.columnDef.filterFn ? (
                    <div className="w-full h-4 ">
                      {flexRender(column.column.columnDef.meta?.filterComponent, column.getContext())}
                    </div>
                  ) : null}
                </div>
              </>
            </ConditionalWrapper>

            {columnOrder && <Droppable id={column.id} />}
          </th>
        ))}
      </tr>
    </thead>
  ));

  return (
    <FullPageTableContext.Provider value={table}>
      <DndContext
        collisionDetection={pointerWithin}
        sensors={sensors}
        onDragStart={(e) => setMovingColumnId(e.active.id as ListColumnId)}
        onDragEnd={(e) => handleDragEnd(e)}
        onDragOver={(e) => setTargetColumnId((e.over?.id as ListColumnId) ?? null)}
      >
        <div className="relative h-full mx-auto pt-0.5">
          <div
            ref={listRef}
            className="pb-4 h-[calc(100vh_-_180px)] w-[calc(100vw_-_20px)] overflow-x-auto overflow-y-auto"
          >
            <table className="mx-auto">
              {Headers}

              <tbody>
                {loading && (
                  <tr>
                    <td>
                      <div className="absolute w-full h-full bg-gray-100 opacity-10"></div>

                      <CenteredSpinner className="absolute top-1/2 left-1/2" />
                    </td>
                  </tr>
                )}

                {table.getRowCount()
                  ? { ...table }.getRowModel().rows.map((row, index) => {
                      getRow(row.id);

                      return (
                        <tr
                          className={cx(index % 2 && 'bg-white', 'bg-gray-100 my-1 hover:brightness-90')}
                          key={`${row.id}-${index}`}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <ConditionalWrapper
                              key={cell.id}
                              condition={!!columnOrder}
                              wrapper={({ children }: { children: JSX.Element }) => (
                                <SortableContext
                                  key={cell.id}
                                  items={(columnOrder as ListColumnId[]) ?? []}
                                  strategy={horizontalListSortingStrategy}
                                >
                                  {children}
                                </SortableContext>
                              )}
                            >
                              <td
                                className={cx(
                                  'p-1 items-center text-sm',
                                  movingColumnId === cell.column.id ? 'opacity-20' : 'opacity-100',
                                )}
                                key={`${cell.column.id}-${index}`}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            </ConditionalWrapper>
                          ))}
                        </tr>
                      );
                    })
                  : !loading && (
                      <tr>
                        <td className="absolute left-0 top-0 flex-center w-full h-full items-center">
                          {tSafe('components.Vehicle.VehicleList.no-results', {
                            defaultValue: 'No results found for the selected filters',
                          })}
                        </td>
                      </tr>
                    )}
              </tbody>
            </table>

            <div className="w-full absolute bottom-0 shadow-card bg-gray-100">
              <div className="flex-center mt-1 -mb-3 min-w-[200px] h-[12px]">
                {!loading && (
                  <div className="flex z-600">
                    {count_records_tfn(viewedItemRange, totalCount ?? 0)}

                    <Button onClick={refetch} className="ml-1 ui-button">
                      <IonIcon name="refreshOutline" className="text-md" />
                    </Button>
                  </div>
                )}
              </div>

              <PageSelector
                className={cx(totalCount && totalCount < maxItemsPerPage && 'opacity-0')}
                totalCount={totalCount}
                currentPage={currentPage}
                onPageChange={(e) => {
                  onPageChange?.(e);
                  setPageOffset?.(e);
                }}
                useTablePagination={useTablePagination}
              />
            </div>
          </div>
        </div>

        <DragOverlay>
          <div className="w-[150px] h-[800px] border-px" style={{ borderColor: 'blue' }}>
            <div className="w-[150px] h-full bg-gray-100 opacity-40" />
          </div>
        </DragOverlay>
      </DndContext>
    </FullPageTableContext.Provider>
  );
};

export default FullPageTable;
