import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import DeviceStatusIcon from 'atoms/DeviceStatusIcon';
import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import Spinner from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { Edit } from 'components/Driver/Detail/Edit';
import { VehicleInfo } from 'components/Driver/Detail/Header/VehicleInfo';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { Status } from 'components/Driver/Detail/Status';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useRole from 'components/User/useRole';
import { getDeviceConnectionStatus } from 'utils';

export const Header = () => {
  const i18nContext = useContext(I18nContext);
  const { isManager } = useRole();
  const currentFleetId = useCurrentFleetId();
  const { data, loading, error } = useDriverDetails();
  const { props: driverModalProps } = useModal({
    title: i18nContext?.commonTranslations.domain.driverDetails.driver_details_text,
  });

  if (loading || !i18nContext)
    return (
      <div className="flex items-center m-0.5 p-1 text-xl bg-white">
        <Spinner />
      </div>
    );

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text, entity_not_found_tfn },
      domain: {
        driver: { driver_text, drivers_text },
        user: {
          fields: { name_text },
        },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!data?.length) return <div>{entity_not_found_tfn(driver_text)}</div>;

  const [
    {
      user: { name, phoneNumber },
      associations,
    },
  ] = data;

  return (
    <div className="flex w-full items-center m-0.5 p-1 text-xl bg-white">
      <Helmet title={name} defer={false} />

      <div className="flex w-full items-center">
        <div className="hover:underline cursor-pointer">
          <Link to={`/${currentFleetId}/list-view`}>{drivers_text}</Link>
        </div>

        <div className="mx-1">&#62;</div>

        <div className="align-middle mx-1">
          <span>
            <DeviceStatusIcon
              connectionStatus={getDeviceConnectionStatus(
                associations?.device?.connected,
                associations?.device?.firstConnected,
                associations?.device?.reporting,
              )}
            />
          </span>
        </div>

        <div className="relative flex flex-col justify-start mx-1">
          <div className="flex">
            <div className="flex justify-center mr-1 w-2">
              <IonIcon name="personCircleOutline" className="text-4xl" aria-label={name_text} />
            </div>

            <span className="font-bold">{name}</span>

            <span className="mx-1 font-bold">
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </span>

            {isManager && (
              <div className="flex flex-1 justify-end mx-1 text-xl">
                <Modal
                  {...driverModalProps}
                  title={tSafe('components.Driver.Detail.Header.edit-driver-details', {
                    defaultValue: 'Edit Driver Details',
                  })}
                  trigger={
                    <Tooltip
                      text={tSafe('components.Driver.Detail.Header.edit-driver-details', {
                        defaultValue: 'Edit Driver Details',
                      })}
                    >
                      <IonIcon name="pencil" className="w-2 h-2 cursor-pointer" />
                    </Tooltip>
                  }
                  triggerClassName="ui-button"
                  contentClassName="w-80"
                >
                  <Edit />
                </Modal>
              </div>
            )}
          </div>

          <VehicleInfo />
        </div>
      </div>

      <div className="flex w-full justify-end">
        <Status />
      </div>
    </div>
  );
};
