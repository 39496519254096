import * as RadixSlider from '@radix-ui/react-slider';
import { useCallback, useState } from 'react';
import tw from 'twin.macro';

const Thumb = tw(RadixSlider.Thumb)`
  block w-2 h-2  bg-white box-content rounded-[10em] shadow-[0 0.2em 1em rgba(0, 0, 0, 0.14)] 
  hover:bg-gray-400
  cursor-pointer
  focus:(shadow-[0 0 0 0.3em rgb(0 0 0 / 0.22)] outline-none)
`;

export const useSlider = (
  initialValue: number[],
  props: Omit<RadixSlider.SliderProps, 'defaultValue' | 'value'> = {},
) => {
  const [value, setValue] = useState(initialValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reset = useCallback(() => setValue(initialValue), []);

  const getProps = useCallback(
    (): RadixSlider.SliderProps => ({
      ...props,
      min: initialValue[0],
      max: initialValue[1],
      defaultValue: initialValue,
      onValueChange: (newValue) => {
        setValue(newValue);
      },
      onValueCommit: () => props.onValueChange?.(value),
      value,
    }),
    [initialValue, props, value],
  );

  return {
    reset,
    setValue,
    getProps,
  };
};

interface SliderProps extends RadixSlider.SliderProps {}

const Slider = ({ className, ...props }: SliderProps) => (
  <>
    {props.value && <div className="pl-0.5">{`${Math.ceil(props.value[0])} - ${Math.ceil(props.value[1])}`}</div>}

    <RadixSlider.Root className={`relative flex items-center h-2 select-none ${className}`} {...props}>
      <RadixSlider.Track className="relative flex-grow bg-gray-400 rounded-full h-[0.3em]">
        <RadixSlider.Range className="absolute h-full bg-secondary rounded-full" />
      </RadixSlider.Track>

      {props.defaultValue ? props.defaultValue.map((_, i) => <Thumb key={i} />) : <Thumb />}
    </RadixSlider.Root>
  </>
);

export default Slider;
