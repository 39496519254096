import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import Slider, { useSlider } from 'atoms/Slider';
import { AggregateListRow } from 'types/settings';

const rangeFilter = ({ id, min, max }: { id: string; min: number; max: number }) => {
  const Component = ({ column: { meta, id } }: { column: ColumnDef<AggregateListRow> }) => {
    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setFilter = useMemo(() => meta?.filterComponent, []);

    const urlRange = useMemo(() => {
      if (!id) return;
      return new URLSearchParams(history.location.search).get(id)?.split(',');
    }, [history.location.search, id]);

    const { getProps, setValue, reset } = useSlider([min, max], {
      onValueChange: (range) => {
        if (!id) return;

        const params = new URLSearchParams(history.location.search);

        if (range[0] !== min || range[1] !== max) {
          params.set(id, range.join(','));
        } else {
          params.delete(id);
        }

        history.push({ pathname: history.location.pathname, search: params.toString() });
      },
    });

    useEffect(() => {
      const range = urlRange?.map(Number) ?? [min, Math.ceil(max)];

      setValue(range);
    }, [urlRange, setFilter, setValue, reset]);

    return <Slider {...getProps()} className="w-full" />;
  };

  return <Component column={{ id }} />;
};

export default rangeFilter;
