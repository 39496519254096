import { TOptions, i18n } from 'i18next';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  activityHistoryEventTypeTranslationMap,
  activityHistorySortTypeTranslationMap,
  batteryNotificationTranslationMap,
  batterySohTranslationMap,
  batteryStateTranslationMap,
  bulkUploadStatusTranslationMap,
  confidenceLevelTranslationMap,
  consenstStatusTranslationMap,
  crashSeverityTranslationMap,
  dashboardLightStatusTranslationMap,
  dashboardLightTranslationMap,
  dashboardWidgetTMap,
  daysOfWeekTranslationMap,
  deviceConnectionStatusTranslationMap,
  deviceDiagnosticTranslationMap,
  deviceTypeTranslationMap,
  distanceUnitTranslationMap,
  driverEventTypeTranslationMap,
  dtcEventTypeTranslationMap,
  dtcStatusTranslationMap,
  fleetMetaFieldTranslationMap,
  fleetNotificationRecipientGroupTranslationMap,
  fuelTypeTranslationMap,
  geofenceBreachFilterTypeTMap,
  geofenceBreachTypeTranslationMap,
  geofenceColorTranslationMap,
  leaderboardTypeTranslationMap,
  notificationTypeTranslationMap,
  odoSourceTranslationMap,
  periodTranslationMap,
  reportPeriodTranslationMap,
  reportScheduleTranslationMap,
  reportTypeTranslationMap,
  topicTranslationMap,
  tripEventTypeTranslationMap,
  tripScoreTypeTranslationMap,
  tripStatisticTypeTranslationMap,
  userLocaleTranslationMap,
  userRoleInputTranslationMap,
  userRoleTranslationMap,
  volumeUnitTranslationMap,
  warningLevelTMap,
} from 'common/descriptionMaps';
import useSettings from 'components/Settings/useSettings';
import {
  CommonTranslations,
  EnumTranslations,
  TFunctioni18n,
  TFunctioni18nWithRequiredDefault,
  TranslationKey,
} from 'types/react-i18next';

export const useT = () => {
  const [t, i18n] = useTranslation();

  const { distanceInMiles, volumeInGallons } = useSettings();

  const tSafe = useCallback(
    (key: TranslationKey, options: Omit<TOptions, 'defaultValue'> & { defaultValue: string }) => t(key, options),
    [t],
  );

  const memoizedEnumMap = useMemo((): EnumTranslations => {
    return {
      dtcEventTypeDescriptionMap: dtcEventTypeTranslationMap(t),
      dtcStatusDescriptionMap: dtcStatusTranslationMap(t),
      batteryStateDescriptionMap: batteryStateTranslationMap(t),
      batterySohStateDescriptionMap: batterySohTranslationMap(t),
      batteryNotificationTypeDescriptionMap: batteryNotificationTranslationMap(t),
      dashboardLightMessageTypeDescriptionMap: dashboardLightTranslationMap(t),
      dashboardLightStatusDescriptionMap: dashboardLightStatusTranslationMap(t),
      reportPeriodDescriptionMap: reportPeriodTranslationMap(t),
      periodDescriptionMap: periodTranslationMap(t),
      reportTypeDescriptionMap: reportTypeTranslationMap(t),
      tripStatisticTypeDescriptionMap: tripStatisticTypeTranslationMap(t, { distanceInMiles, volumeInGallons }),
      reportScheduleDescriptionMap: reportScheduleTranslationMap(t),
      daysOfWeekDescriptionMap: daysOfWeekTranslationMap(t),
      deviceDiagnosticDescriptionMap: deviceDiagnosticTranslationMap(t),
      deviceConnectionStatusDescriptionMap: deviceConnectionStatusTranslationMap(t),
      userLocaleDescriptionMap: userLocaleTranslationMap(t),
      bulkUploadStatusDescriptionMap: bulkUploadStatusTranslationMap(t),
      driverEventTypeDescriptionMap: driverEventTypeTranslationMap(t),
      crashSeverityDescriptionMap: crashSeverityTranslationMap(t),
      confidenceLevelDescriptionMap: confidenceLevelTranslationMap(t),
      consenstStatusDescriptionMap: consenstStatusTranslationMap(t),
      deviceTypeDescriptionMap: deviceTypeTranslationMap(t),
      distanceUnitDescriptionMap: distanceUnitTranslationMap(t),
      fleetMetaFieldDescriptionMap: fleetMetaFieldTranslationMap(t),
      fuelTypeDescriptionMap: fuelTypeTranslationMap(t),
      geofenceBreachTypeDescriptionMap: geofenceBreachTypeTranslationMap(t),
      geofenceColorDescriptionMap: geofenceColorTranslationMap(t),
      leaderboardTypeDescriptionMap: leaderboardTypeTranslationMap(t),
      notificationTypeDescriptionMap: notificationTypeTranslationMap(t),
      odoSourceDescriptionMap: odoSourceTranslationMap(t),
      topicDescriptionMap: topicTranslationMap(t),
      tripScoreDescriptionMap: tripScoreTypeTranslationMap(t),
      tripEventTypeDescriptionMap: tripEventTypeTranslationMap(t),
      activityHistorySortTypeDescriptionMap: activityHistorySortTypeTranslationMap(t),
      userRoleDescriptionMap: userRoleTranslationMap(t),
      userRoleInputDescriptionMap: userRoleInputTranslationMap(t),
      volumeUnitDescriptionMap: volumeUnitTranslationMap(t),
      activityHistoryEventTypeDescriptionMap: activityHistoryEventTypeTranslationMap(t),
      geofenceBreachFilterTypeDescriptionMap: geofenceBreachFilterTypeTMap(t),
      warningLevelDescriptionMap: warningLevelTMap(t),
      dashboardWidgetDescriptionMap: dashboardWidgetTMap(t),
      fleetNotificationRecipientGroupDescriptionMap: fleetNotificationRecipientGroupTranslationMap(t),
    };
  }, [distanceInMiles, t, volumeInGallons]);

  const memoizedCommonTranslations = useMemo(() => {
    // domain nouns
    const association_translatedWithCount = (count: number) =>
      tSafe('common.domain.association.association', { count, defaultValue: 'Association' });
    const battery_translatedWithCount = (count: number) =>
      tSafe('common.domain.battery.battery', { count, defaultValue: 'Battery' });
    const dashboard_light_translatedWithCount = (count: number) =>
      tSafe('common.domain.dashboardLight.dashboardLight', { count, defaultValue: 'Dashboard Light' });
    const dtc_translatedWithCount = (count: number) => tSafe('common.domain.dtc.dtc', { count, defaultValue: 'DTC' });
    const driver_translatedWithCount = (count: number) =>
      tSafe('common.domain.driver.drivers', { count, defaultValue: 'Drivers' });
    const vehicle_translatedWithCount = (count: number) =>
      tSafe('common.domain.vehicle.vehicles', { count, defaultValue: 'Vehicles' });
    const fleet_translatedWithCount = (count: number) =>
      tSafe('common.domain.fleet.fleets', { count, defaultValue: 'Fleets' });
    const impact_translatedWithCount = (count: number) =>
      tSafe('common.domain.impact.impacts', { count, defaultValue: 'Impacts' });
    const impact_event_translatedWithCount = (count: number) =>
      tSafe('common.domain.impact.impact-events', { count, defaultValue: 'Impact Events' });
    const trip_translatedWithCount = (count: number) =>
      tSafe('common.domain.trip.trip', { count, defaultValue: 'Trip' });
    const lastKnownLocation_translatedWithCount = (count = 1) =>
      tSafe('common.domain.lastKnownLocation.lastKnownLocations', { count, defaultValue: 'Last Known Location' });
    const geofence_translatedWithCount = (count: number) =>
      tSafe('common.domain.geofence.geofences', { count, defaultValue: 'Geofences' });
    const user_translatedWithCount = (count: number) =>
      tSafe('common.domain.user.users', { count, defaultValue: 'Users' });
    const report_translatedWithCount = (count: number) =>
      tSafe('common.domain.report.reports', { count, defaultValue: 'Reports' });

    const map: CommonTranslations = {
      general: {
        unavailable_text: tSafe('common.general.unavailable', { defaultValue: 'Unavailable' }),
        unknown_text: tSafe('common.general.unknown', { defaultValue: 'Unknown' }),
        loading_text: tSafe('common.general.loading', { defaultValue: 'Loading...' }),
        all_text: tSafe('common.general.all', { defaultValue: 'All' }),
        none_text: tSafe('common.general.none', { defaultValue: 'None' }),
        unassigned_text: tSafe('common.general.unassigned', { defaultValue: 'Unassigned' }),
        utilisation_text: tSafe('common.general.utilisation', { defaultValue: 'Utilisation' }),
        aborted_text: tSafe('common.general.aborted', { defaultValue: 'Aborted' }),
        time_text: tSafe('common.general.time', { defaultValue: 'Time' }),
        description_text: tSafe('common.general.description', { defaultValue: 'Description' }),
        sort_by_text: tSafe('common.general.sort-by', { defaultValue: 'Sort by' }),
        refresh_text: tSafe('common.general.refresh', { defaultValue: 'Refresh' }),
        edit_mode_text: tSafe('common.general.edit-mode', { defaultValue: 'Edit Mode' }),
        logout_text: tSafe('common.general.logout', { defaultValue: 'Logout' }),
        no_text: tSafe('common.general.no', { defaultValue: 'No' }),
        yes_text: tSafe('common.general.yes', { defaultValue: 'Yes' }),
        active_text: tSafe('common.general.active', { defaultValue: 'Active' }),
        inactive_text: tSafe('common.general.inactive', { defaultValue: 'Inactive' }),
        redirecting_text: tSafe('common.general.redirecting', { defaultValue: 'Redirecting...' }),
        success_text: tSafe('common.general.success', { defaultValue: 'Success!' }),
        close_text: tSafe('common.general.close', { defaultValue: 'Close' }),
        here_text: tSafe('common.general.here', { defaultValue: 'Here' }),
        some_text: tSafe('common.general.some', { defaultValue: 'Some' }),
        off_text: tSafe('common.general.off', { defaultValue: 'Off' }),
        on_text: tSafe('common.general.on', { defaultValue: 'On' }),
        search_ellipsis_text: tSafe('common.general.search-ellipsis', { defaultValue: 'Search...' }),
        back_text: tSafe('common.general.back', { defaultValue: 'Back' }),
        enabled_text: tSafe('common.general.enabled', { defaultValue: 'Enabled' }),
        edit_text: tSafe('common.general.edit', { defaultValue: 'Edit' }),
        create_text: tSafe('common.general.create', { defaultValue: 'Create' }),
        rendering_text: tSafe('common.general.rendering', { defaultValue: 'Rendering...' }),
        event_last_n_days_tfn: (event, numberOfDays) =>
          tSafe('common.general.event-last-n-days', {
            event,
            numberOfDays,
            defaultValue: '{{event}} over the last {{numberOfDays}}} days',
          }),
        n_day_period_text: (numberOfDays) =>
          tSafe('common.general.n-day-period', { numberOfDays, defaultValue: '{{numberOfDays}} day period' }),
      },
      forms: {
        buttons: {
          cancel_text: tSafe('common.forms.buttons.cancel', { defaultValue: 'Cancel' }),
          save_text: tSafe('common.forms.buttons.save', { defaultValue: 'Save' }),
          delete_text: tSafe('common.forms.buttons.delete', { defaultValue: 'Delete' }),
          reset_text: tSafe('common.forms.buttons.reset', { defaultValue: 'Reset' }),
          confirm_text: tSafe('common.forms.buttons.confirm', { defaultValue: 'Confirm' }),
          clear_text: tSafe('common.forms.buttons.clear', { defaultValue: 'Clear' }),
          add_text: tSafe('common.forms.buttons.add', { defaultValue: 'Add' }),
          saving_ellipsis_text: tSafe('common.forms.buttons.saving-ellipsis', { defaultValue: 'Saving...' }),
        },
        required_text: tSafe('common.forms.required', { defaultValue: 'required' }),
        ensure_all_required_fields_filled_text: tSafe('common.forms.ensure-all-required-fields-filled', {
          defaultValue: 'Please ensure all required fields are filled out',
        }),
      },
      errors: {
        failed_to_fetch_data_text: tSafe('common.errors.failed-to-fetch-data', {
          defaultValue: 'Failed to fetch data!',
        }),
        no_data_fleet_text: tSafe('common.errors.no-data-fleet', { defaultValue: 'No data for the selected fleet' }),
        error_text: tSafe('common.errors.generic', { defaultValue: 'An error occurred!' }),
        not_found_error_text: tSafe('common.errors.notFound', { defaultValue: 'Not found!' }),
        entity_not_found_tfn: (entityName) =>
          tSafe('common.errors.entityNotFound', { entityName, defaultValue: '{{entityName}} Not found!' }),
      },
      uom: {
        gal_text: tSafe('common.uom.gal', { defaultValue: 'gal' }),
        kilometres_text: tSafe('common.uom.kilometres', { defaultValue: 'kilometres' }),
        mi_text: tSafe('common.uom.mi', { defaultValue: 'mi' }),
        km_text: tSafe('common.uom.km', { defaultValue: 'km' }),
        l_text: tSafe('common.uom.l', { defaultValue: 'l' }),
        miles_text: tSafe('common.uom.miles', { defaultValue: 'miles' }),
        kph_text: tSafe('common.uom.kph', { defaultValue: 'kph' }),
        mph_text: tSafe('common.uom.mph', { defaultValue: 'mph' }),
        seconds_text: tSafe('common.uom.seconds', { defaultValue: 'seconds' }),
        hours_text: tSafe('common.uom.hours', { defaultValue: 'hours' }),
        minutes_text: tSafe('common.uom.minutes', { defaultValue: 'minutes' }),
        volts_text: tSafe('common.uom.volts', { defaultValue: 'volts' }),
        bar_text: tSafe('common.uom.bar', { defaultValue: 'bar' }),
        user_distance_text: distanceInMiles ? t('common.uom.mi') : t('common.uom.km'),
      },
      pagination: {
        back_to_top_text: tSafe('common.general.pagination.back-to-top', { defaultValue: 'Back to top' }),
        next_page_text: tSafe('common.general.pagination.next-page', { defaultValue: 'Next page' }),
        previous_page_text: tSafe('common.general.pagination.previous-page', { defaultValue: 'Previous page' }),
        current_page_position_tfn: (currentPageNumber, totalPageCount) =>
          tSafe('common.general.pagination.current-page-position', {
            currentPageNumber,
            totalPageCount,
            defaultValue: 'Page {{currentPageNumber}} of {{totalPageCount}}',
          }),
        count_records_tfn: (viewed, recordCount) =>
          tSafe('common.general.pagination.record-count', {
            recordCount,
            viewed,
            defaultValue: '{{viewed}} of {{recordCount}} records',
          }),
        load_more: tSafe('common.general.load-more', { defaultValue: 'Load more' }),
      },
      sorting: {
        most_recent_first_text: tSafe('common.sorting.most-recent-first', { defaultValue: 'Most recent first' }),
        oldest_first_text: tSafe('common.sorting.oldest-first', { defaultValue: 'Oldest first' }),
      },
      domain: {
        common: {
          guid_text: tSafe('common.domain.common.guid', { defaultValue: 'GUID' }),
          odometer_text: tSafe('common.domain.odometer', { defaultValue: 'Odometer' }),
          location_text: tSafe('common.domain.location', { defaultValue: 'Location' }),
          extended_text: tSafe('common.domain.extended', { defaultValue: 'Extended' }),
          recommendation_text: tSafe('common.domain.recommendation', {
            defaultValue: 'Recommendation',
          }),
          address_text: tSafe('common.domain.address', { defaultValue: 'Address' }),
          time_text: tSafe('common.domain.time', { defaultValue: 'Time' }),
          lat_text: tSafe('common.domain.lat', { defaultValue: 'Lat' }),
          latitude_text: tSafe('common.domain.latitude', { defaultValue: 'Latitude' }),
          lng_text: tSafe('common.domain.lng', { defaultValue: 'Lng' }),
          longtitude_text: tSafe('common.domain.longtitude', { defaultValue: 'Longtitude' }),
        },
        association: {
          association_text: association_translatedWithCount(1),
          associations_text: association_translatedWithCount(0),
        },
        impact: {
          impact_tfnWithCount: impact_translatedWithCount,
          impact_event_tfnWithCount: impact_event_translatedWithCount,
          impact_event_text: impact_event_translatedWithCount(1),
          impact_events_text: impact_event_translatedWithCount(0),
          impact_text: impact_translatedWithCount(1),
          impacts_text: impact_translatedWithCount(0),
          name: tSafe('common.domain.impact.name', { defaultValue: 'Impact' }),
          fields: {
            crashSeverity_text: tSafe('common.domain.impact.fields.crashSeverity', { defaultValue: 'Crash Severity' }),
            force_text: tSafe('common.domain.impact.fields.force', { defaultValue: 'Force' }),
          },
        },
        dashboardLight: {
          dashboard_light_text: dashboard_light_translatedWithCount(1),
          dashboard_lights_text: dashboard_light_translatedWithCount(0),
          fields: {
            type_text: tSafe('common.domain.dashboardLight.fields.type', {
              defaultValue: 'Dashboard Light Event Type',
            }),
          },
        },
        device: {
          fields: {
            device_connection_status_text: tSafe('common.domain.device.fields.deviceStatus', {
              defaultValue: 'Device Status',
            }),
            deviceConnectionTooltip_text: tSafe('common.domain.device.fields.deviceStatusTooltip', {
              defaultValue:
                'The device is connected, but not transmitting. Unplug for at least 30 minutes before reconnecting.',
            }),
            type_text: tSafe('common.domain.device.fields.type', {
              defaultValue: 'Connection Type',
            }),
            externalId_text: tSafe('common.domain.device.fields.externalId', {
              defaultValue: 'Device Serial',
            }),
            firmware_text: tSafe('common.domain.device.fields.firmware', {
              defaultValue: 'Current Firmware',
            }),
            firstConnected_text: tSafe('common.domain.device.fields.firstConnected', {
              defaultValue: 'First Connected',
            }),
            id_text: tSafe('common.domain.device.fields.id', {
              defaultValue: 'Device ID',
            }),
            imei_text: tSafe('common.domain.device.fields.imei', {
              defaultValue: 'IMEI',
            }),
            latestConnectionDate_text: tSafe('common.domain.device.fields.latestConnectionDate', {
              defaultValue: 'Latest Connection Date',
            }),
            latestHeartbeat_text: tSafe('common.domain.device.fields.latestHeartbeat', {
              defaultValue: 'Latest Heartbeat',
            }),
            model_text: tSafe('common.domain.device.fields.model', {
              defaultValue: 'Device Model',
            }),
            reporting_text: tSafe('common.domain.device.fields.reporting', {
              defaultValue: 'Reporting',
            }),
            notReporting_text: tSafe('common.domain.device.fields.notReporting', {
              defaultValue: 'Not Reporting',
            }),
            reportingStatus_text: tSafe('common.domain.device.fields.reportingStatus', {
              defaultValue: 'Reporting Status',
            }),
            sourceType_text: tSafe('common.domain.device.fields.sourceType', {
              defaultValue: 'Source Type',
            }),
            device_guid_text: tSafe('common.domain.device.fields.deviceGUID', {
              defaultValue: 'Device GUID',
            }),
          },
        },
        deviceDiagnostic: {
          fields: {
            type_text: tSafe('common.domain.deviceDiagnostic.fields.type', {
              defaultValue: 'Event Type',
            }),
          },
        },
        dtc: {
          dtc_text: dtc_translatedWithCount(1),
          dtcs_text: dtc_translatedWithCount(0),
          fields: {
            dtcType_text: tSafe('common.domain.dtc.fields.dtcType', { defaultValue: 'DTC Type' }),
            code_text: tSafe('common.domain.dtc.fields.code', { defaultValue: 'Code' }),
            status_text: tSafe('common.domain.dtc.fields.status', { defaultValue: 'Status' }),
          },
        },
        battery: {
          battery_text: battery_translatedWithCount(1),
          batteries_text: battery_translatedWithCount(0),
          batteryNotification: {
            name: tSafe('common.domain.battery.batteryNotification.name', { defaultValue: 'Battery Event' }),
            fields: {
              voltage_text: tSafe('common.domain.battery.batteryNotification.fields.voltage', {
                defaultValue: 'Voltage',
              }),
              notificationType_text: tSafe('common.domain.battery.batteryNotification.fields.notificationType', {
                defaultValue: 'Notification Type',
              }),
            },
          },
        },
        trip: {
          trip_text: trip_translatedWithCount(1),
          trips_text: trip_translatedWithCount(0),
          fields: {
            fuel_level_text: tSafe('common.domain.trip.fields.fuelLevel', { defaultValue: 'Fuel Level' }),
          },
        },
        tripEventDetails: {
          fields: {
            duration_text: tSafe('common.domain.tripEventDetails.fields.duration', { defaultValue: 'Duration' }),
            geofenceBreachType_text: tSafe('common.domain.tripEventDetails.fields.geofenceBreachType', {
              defaultValue: 'Breach Type',
            }),
            geofenceName_text: tSafe('common.domain.tripEventDetails.fields.geofenceName', {
              defaultValue: 'Geofence',
            }),
            idleDuration_text: tSafe('common.domain.tripEventDetails.fields.idleDuration', {
              defaultValue: 'Idle Duration',
            }),
            idleThreshold_text: tSafe('common.domain.tripEventDetails.fields.idleThreshold', {
              defaultValue: 'Idle Threshold',
            }),
            maxAcceleration_text: tSafe('common.domain.tripEventDetails.fields.maxAcceleration', {
              defaultValue: 'Max Acceleration',
            }),
            maxDeceleration_text: tSafe('common.domain.tripEventDetails.fields.maxDeceleration', {
              defaultValue: 'Max Deceleration',
            }),
            speed_text: tSafe('common.domain.tripEventDetails.fields.speed', { defaultValue: 'Speed' }),
          },
        },
        tripRecord: {
          fields: {
            avgSpeed_text: tSafe('common.domain.tripRecord.fields.avgSpeed', { defaultValue: 'Average Speed' }),
            distance_text: tSafe('common.domain.tripRecord.fields.distance', { defaultValue: 'Distance Travelled' }),
            maxSpeed_text: tSafe('common.domain.tripRecord.fields.maxSpeed', { defaultValue: 'Max Speed' }),
            minSpeed_text: tSafe('common.domain.tripRecord.fields.minSpeed', { defaultValue: 'Min Speed' }),
            duration_text: tSafe('common.domain.tripRecord.fields.duration', { defaultValue: 'Trip Duration' }),
          },
        },
        driver: {
          driver_translatedWithCount,
          driver_text: driver_translatedWithCount(1),
          drivers_text: driver_translatedWithCount(0),
          longIdling_text: tSafe('common.enums.DriverEventType.LONG_IDLING', { defaultValue: 'Long idling' }),
          longIdlingTooltip_text: tSafe('components.Dashboard.DriverEventCountWidget.long-idling-tooltip', {
            defaultValue: 'Long idling',
          }),
          fields: { driver_name_text: tSafe('common.domain.driver.fields.name', { defaultValue: 'Driver Name' }) },
          unassigned_drivers_text: tSafe('common.domain.driver.unassigned-drivers', {
            defaultValue: 'Unassigned drivers',
          }),
        },
        driverDetails: {
          driver_details_text: tSafe('common.domain.driverDetails.driver-details', { defaultValue: 'Driver Details' }),
          view_driver_text: tSafe('common.domain.driverDetails.view-driver', {
            defaultValue: 'View driver',
          }),
          fields: {
            activity_text: tSafe('common.domain.driverDetails.fields.activity', { defaultValue: 'Driver Events' }),
            driverScores_text: tSafe('common.domain.driverDetails.fields.driverScores', {
              defaultValue: 'Driver Scores',
            }),
            geofences_text: tSafe('common.domain.driverDetails.fields.geofences', { defaultValue: 'Driver Geofences' }),
            statistics_text: tSafe('common.domain.driverDetails.fields.statistics', {
              defaultValue: 'Driver Statistics',
            }),
            id_text: tSafe('common.domain.driverDetails.fields.id', { defaultValue: 'Driver ID' }),
            vehicleInfo_text: tSafe('common.domain.driverDetails.fields.vehicleInfo', {
              defaultValue: 'Vehicle Info',
            }),
          },
        },
        vehicle: {
          vehicle_translatedWithCount,
          vehicle_text: vehicle_translatedWithCount(1),
          vehicles_text: vehicle_translatedWithCount(0),
          fields: {
            licencePlate_text: tSafe('common.domain.vehicle.fields.licencePlate', { defaultValue: 'Licence Plate' }),
            fuelStatus_text: tSafe('common.domain.vehicle.fields.fuelStatus', { defaultValue: 'Fuel Status' }),
            fuelType_text: tSafe('common.domain.vehicle.fields.fuelType', { defaultValue: 'Fuel Type' }),
            id_text: tSafe('common.domain.vehicle.fields.id', { defaultValue: 'Vehicle ID' }),
            make_text: tSafe('common.domain.vehicle.fields.make', { defaultValue: 'Make' }),
            model_text: tSafe('common.domain.vehicle.fields.model', { defaultValue: 'Model' }),
            vin_text: tSafe('common.domain.vehicle.fields.vin', { defaultValue: 'VIN' }),
            year_text: tSafe('common.domain.vehicle.fields.year', { defaultValue: 'Year' }),
            seedOdo_text: tSafe('common.domain.vehicle.fields.seedOdo', { defaultValue: 'Seed Odometer Reading' }),
            seedOdoTime_text: tSafe('common.domain.vehicle.fields.seedOdoTime', {
              defaultValue: 'Seed Odometer Reading Time',
            }),
            guid_text: tSafe('common.domain.vehicle.fields.guid', { defaultValue: 'Vehicle GUID' }),
          },
          unassigned_vehicles_text: tSafe('common.domain.vehicle.unassigned-vehicles', {
            defaultValue: 'Unassigned vehicles',
          }),
        },
        vehicleDetails: {
          vehicle_details_text: tSafe('common.domain.vehicleDetails.vehicle-details', {
            defaultValue: 'Vehicle Details',
          }),
          view_vehicle_text: tSafe('common.domain.vehicleDetails.view-vehicle', {
            defaultValue: 'View vehicle',
          }),
          fields: {
            batteryStatus_text: tSafe('common.domain.vehicleDetails.fields.batteryStatus', {
              defaultValue: 'Battery Status',
            }),
            connectionInformation_text: tSafe('common.domain.vehicleDetails.fields.connectionInformation', {
              defaultValue: 'Connection Information',
            }),
            activity_text: tSafe('common.domain.vehicleDetails.fields.events', { defaultValue: 'Vehicle Events' }),
            geofences_text: tSafe('common.domain.vehicleDetails.fields.geofences', {
              defaultValue: 'Vehicle Geofences',
            }),
            activeDtcs_text: tSafe('common.domain.vehicleDetails.fields.activeDtcs', { defaultValue: 'Active DTCs' }),
            status_text: tSafe('common.domain.vehicleDetails.fields.vehicleStatus', { defaultValue: 'Vehicle Status' }),
            lastKnownLocation_text: tSafe('common.domain.vehicleDetails.fields.lastKnownLocations', {
              defaultValue: 'Last Known Location',
            }),
            vehicle_levels_text: tSafe('common.domain.vehicleDetails.fields.vehicleLevels', {
              defaultValue: 'Vehicle Levels',
            }),
            tyre_pressure_text: tSafe('common.domain.vehicleDetails.fields.tyrePressure', {
              defaultValue: 'Tyre Pressure',
            }),
            typePressures: {
              frontLeft_text: tSafe('common.domain.vehicleDetails.fields.typePressures.frontLeft', {
                defaultValue: 'Front Left',
              }),
              frontRight_text: tSafe('common.domain.vehicleDetails.fields.typePressures.frontRight', {
                defaultValue: 'Front Right',
              }),
              rearLeft_text: tSafe('common.domain.vehicleDetails.fields.typePressures.rearLeft', {
                defaultValue: 'Rear Left',
              }),
              rearRight_text: tSafe('common.domain.vehicleDetails.fields.typePressures.rearRight', {
                defaultValue: 'Rear Right',
              }),
            },
            battery_voltage_text: tSafe('common.domain.vehicleDetails.fields.batteryVoltage', {
              defaultValue: 'Battery Voltage',
            }),
            service_status_text: tSafe('common.domain.vehicleDetails.fields.serviceStatus', {
              defaultValue: 'Service Status',
            }),
            battery_warnings_text: tSafe('common.domain.vehicleDetails.fields.batteryWarnings', {
              defaultValue: 'Battery Warnings',
            }),
            battery_health_text: tSafe('common.domain.vehicleDetails.fields.batteryHealth', {
              defaultValue: 'Battery Health',
            }),
            fuel_level_text: tSafe('common.domain.vehicleDetails.fields.fuelLevel', {
              defaultValue: 'Fuel Level',
            }),
            activeDashboardLights_text: tSafe('common.domain.vehicleDetails.fields.activeDashboardLights', {
              defaultValue: 'Active Dashboard Lights',
            }),
            mil_status_text: tSafe('common.domain.vehicleDetails.fields.milStatus', {
              defaultValue: 'MIL Status',
            }),
            fuel_status_text: tSafe('common.domain.vehicleDetails.fields.fuelStatus', {
              defaultValue: 'Fuel Status',
            }),
          },
        },
        geofence: {
          geofence_translatedWithCount,
          geofence_text: geofence_translatedWithCount(1),
          geofences_text: geofence_translatedWithCount(0),
          fields: {
            color_text: tSafe('common.domain.geofence.fields.color', { defaultValue: 'Color' }),
            coords_text: tSafe('common.domain.geofence.fields.coords', { defaultValue: 'Coordinates' }),
            name_text: tSafe('common.domain.geofence.fields.name', { defaultValue: 'Geofence Name' }),
            radius_text: tSafe('common.domain.geofence.fields.radius', { defaultValue: 'Radius' }),
          },
        },
        fleet: {
          fleet_translatedWithCount,
          fleet_text: fleet_translatedWithCount(1),
          fleets_text: fleet_translatedWithCount(0),
          fields: {
            name_text: tSafe('common.domain.fleet.fields.name', { defaultValue: 'Fleet Name' }),
          },
        },
        user: {
          user_translatedWithCount,
          user_text: user_translatedWithCount(1),
          users_text: user_translatedWithCount(0),
          fields: {
            active_text: tSafe('common.domain.user.fields.active', { defaultValue: 'Active' }),
            actions_text: tSafe('common.domain.user.fields.actions', { defaultValue: 'Actions' }),
            email_text: tSafe('common.domain.user.fields.email', { defaultValue: 'Email' }),
            name_text: tSafe('common.domain.user.fields.name', { defaultValue: 'Name' }),
            phoneNumber_text: tSafe('common.domain.user.fields.phoneNumber', { defaultValue: 'Phone Number' }),
            role_text: tSafe('common.domain.user.fields.role', { defaultValue: 'Role' }),
            accessibleFleets_text: tSafe('common.domain.user.fields.accessibleFleets', {
              defaultValue: 'Accessible Fleets',
            }),
            sendPasswordEmail_text: tSafe('common.domain.user.fields.sendPasswordEmail', {
              defaultValue: 'Send password email',
            }),
          },
        },
        notification: {
          notification_translatedWithCount: (count) =>
            tSafe('common.domain.notification.notifications', { count, defaultValue: 'Notifications' }),
        },
        lastKnownLocation: {
          lastKnownLocation_translatedWithCount,
          last_location_unknown_text: tSafe('common.domain.lastKnownLocation.unknown', {
            defaultValue: 'Last Location Unknown',
          }),
          last_known_location_text: lastKnownLocation_translatedWithCount(1),
          fields: {
            lat_text: tSafe('common.domain.lastKnownLocation.fields.lat', { defaultValue: 'Lat' }),
            lng_text: tSafe('common.domain.lastKnownLocation.fields.lng', { defaultValue: 'Lng' }),
            latitude_text: tSafe('common.domain.lastKnownLocation.fields.latitude', { defaultValue: 'Latitude' }),
            longitude_text: tSafe('common.domain.lastKnownLocation.fields.longtitude', {
              defaultValue: 'Longtitude',
            }),
          },
          lat_lng_tfn: (latitude, longtitude) =>
            tSafe('common.domain.lastKnownLocation.lat-lng-interpolated', {
              latitude,
              longtitude,
              defaultValue: 'Lat: {{latitude}}, Lng: {{longtitude}}',
            }),
        },
        report: {
          report_translatedWithCount,
          report_text: report_translatedWithCount(1),
          reports_text: report_translatedWithCount(0),
          fields: {
            createdAt_text: tSafe('common.domain.report.fields.createdAt', { defaultValue: 'Created At' }),
            dateFrom_text: tSafe('common.domain.report.fields.dateFrom', { defaultValue: 'Date From' }),
            dateTo_text: tSafe('common.domain.report.fields.dateTo', { defaultValue: 'Date To' }),
            dateRange_text: tSafe('common.domain.report.fields.dateRange', { defaultValue: 'Date Range' }),
            dayOfMonth_text: tSafe('common.domain.report.fields.dayOfMonth', { defaultValue: 'Date of Month' }),
            dayOfWeek_text: tSafe('common.domain.report.fields.dayOfWeek', { defaultValue: 'Date of Week' }),
            schedule_text: tSafe('common.domain.report.fields.schedule', { defaultValue: 'Report Schedule' }),
            reportSentAt_text: tSafe('common.domain.report.fields.reportSentAt', { defaultValue: 'Report Sent At' }),
            type_text: tSafe('common.domain.report.fields.reportType', { defaultValue: 'Report Type' }),
          },
        },
        settings: {
          fields: {
            currencyUnit_text: tSafe('common.domain.settings.fields.currencyUnit', { defaultValue: 'Currency Unit' }),
            dieselPricePerLitre_text: tSafe('common.domain.settings.fields.dieselPricePerLitre', {
              defaultValue: 'Diesel Price Per Litre',
            }),
            petrolPricePerLitre_text: tSafe('common.domain.settings.fields.petrolPricePerLitre', {
              defaultValue: 'Petrol Price Per Litre',
            }),
            idleTimeUnit_text: tSafe('common.domain.settings.fields.idleTimeUnit', {
              defaultValue: 'Idle Time Unit',
            }),
            locale_text: tSafe('common.domain.settings.fields.locale', {
              defaultValue: 'Language',
            }),
            volumeUnit_text: tSafe('common.domain.settings.fields.volumeUnit', {
              defaultValue: 'Volume Unit',
            }),
            distanceUnit_text: tSafe('common.domain.settings.fields.distanceUnit', {
              defaultValue: 'Distance Unit',
            }),
          },
        },
        bulkUploadResult: {
          fields: {
            bulkUploadId_text: tSafe('common.domain.bulkUploadResult.fields.bulkUploadId', {
              defaultValue: 'Bulk Upload ID',
            }),
          },
        },
      },
    };

    return map;
  }, [distanceInMiles, t, tSafe]);

  return { t, tSafe, i18n, commonTranslations: { ...memoizedCommonTranslations, enums: memoizedEnumMap } };
};

export const I18nContext = React.createContext<{
  t: TFunctioni18n;
  tSafe: TFunctioni18nWithRequiredDefault;
  commonTranslations: CommonTranslations & { enums: EnumTranslations };
  i18n: i18n;
} | null>(null);

export const I18nContextProvider = ({ children }: { children: ReactNode }) => {
  const useTRes = useT();

  return <I18nContext.Provider value={useTRes}>{children}</I18nContext.Provider>;
};
