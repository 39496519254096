import { format } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { DashboardLightEvent } from 'generated/graphql';
import { cx, kmToMi } from 'utils';

interface DashboardLightEventDetailProps {
  event: Partial<DashboardLightEvent>;
}

export const DashboardLightEventDetail = ({ event }: DashboardLightEventDetailProps) => {
  const i18nContext = useContext(I18nContext);
  const { description, extendedDescription, odometer, recommendation, status, time } = event;

  const { distanceInMiles } = useSettings();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { time_text, unavailable_text, description_text },
      domain: {
        common: { odometer_text, extended_text, recommendation_text },
      },
    },
  } = i18nContext;

  const odometerInKm = odometer ? odometer / 1000 : undefined;
  const odometerConverted = distanceInMiles && odometerInKm ? kmToMi(odometerInKm) : odometerInKm;
  const odometerRounded = odometerConverted ? Math.round(odometerConverted) : undefined;
  const odometerUnit = distanceInMiles ? 'miles' : 'km';

  return (
    <div className="mt-2">
      <InfoPanel>
        <Icon
          name={dashboardLightsIconMap[event.messageType!]}
          className={cx('mb-2 !w-4', status === 'ON' ? 'text-error' : 'text-success')}
        />

        <InfoPanelItem
          description={odometer_text}
          value={odometerRounded}
          unitOfMeasure={odometerRounded ? odometerUnit : undefined}
        />

        <InfoPanelItem description={description_text} value={description} />

        <InfoPanelItem description={time_text} value={time ? format(Date.parse(time), 'PPPPp') : unavailable_text} />

        <InfoPanelItem description={extended_text} value={extendedDescription} />

        <InfoPanelItem description={recommendation_text} value={recommendation} />
      </InfoPanel>
    </div>
  );
};
