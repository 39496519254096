import { format } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { impactEventsStyleMap } from 'common/driverEvents';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { DriverImpactEvent, VehicleImpactEvent } from 'generated/graphql';
import { cx, kmToMi } from 'utils';

interface ImpactEventDetailProps {
  event: Partial<VehicleImpactEvent | DriverImpactEvent>;
}

export const ImpactEventDetail = ({ event }: ImpactEventDetailProps) => {
  const i18nContext = useContext(I18nContext);
  const { crashSeverity, force, time, odometer, coords: latLng } = event;
  const { distanceInMiles } = useSettings();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
      uom: { miles_text, km_text },
      domain: {
        common: { odometer_text, location_text, time_text },

        impact: {
          fields: { crashSeverity_text, force_text },
        },
      },
      enums: { crashSeverityDescriptionMap },
    },
  } = i18nContext;

  const odometerInKm = odometer ? odometer / 1000 : undefined;
  const odometerConverted = distanceInMiles && odometerInKm ? kmToMi(odometerInKm) : odometerInKm;
  const odometerRounded = odometerConverted ? Math.round(odometerConverted) : undefined;
  const odometerUnit = distanceInMiles ? miles_text : km_text;

  return (
    <div className="flex flex-col">
      <div className="mx-1 my-2 w-5 text-center text-sm">
        <Icon name="impact" className={cx('!w-4', crashSeverity && impactEventsStyleMap[crashSeverity])} />
      </div>

      <InfoPanelItem description={time_text} value={time ? format(Date.parse(time), 'PPPPp') : unavailable_text} />

      <InfoPanel>
        {crashSeverity ? (
          <InfoPanelItem
            description={crashSeverity_text}
            value={crashSeverity ? crashSeverityDescriptionMap[crashSeverity] : unavailable_text}
          />
        ) : null}

        <InfoPanelItem description={force_text} value={force} />

        <InfoPanelItem
          description={odometer_text}
          value={odometerRounded ? odometerRounded : unavailable_text}
          unitOfMeasure={odometerRounded ? odometerUnit : undefined}
        />

        <InfoPanelItem
          description={location_text}
          value={latLng ? `${latLng.lat},${latLng.lng}` : unavailable_text}
          showCopyToClipboard
        />
      </InfoPanel>
    </div>
  );
};
