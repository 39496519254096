import { format, parseISO } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { impactEventsStyleMap } from 'common/driverEvents';
import { I18nContext } from 'common/useT';
import {
  DateTimeColumn,
  DefaultAdditionalColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
} from 'components/ActivityHistoryList/styledComponents';
import { DriverImpactEvent, VehicleImpactEvent } from 'generated/graphql';
import { cx } from 'utils';

interface ImpactEventRowItemProps {
  event: Partial<VehicleImpactEvent | DriverImpactEvent>;
  time: string;
}

export const ImpactEventRowItem = ({ event, time }: ImpactEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { crashSeverityDescriptionMap },
      domain: {
        impact: { impact_event_text },
      },
    },
  } = i18nContext;

  const { crashSeverity, force } = event;

  return (
    <EventDetailContainer>
      <DateTimeColumn>{format(parseISO(time), 'iii PPp')}</DateTimeColumn>

      <EventTypeColumn>{impact_event_text}</EventTypeColumn>

      <IconColumn>
        <Icon name="impact" className={cx('!w-3', crashSeverity && impactEventsStyleMap[crashSeverity])} />
      </IconColumn>

      <DefaultAdditionalColumn>
        {crashSeverity ? crashSeverityDescriptionMap[crashSeverity] : null}
      </DefaultAdditionalColumn>

      <DefaultAdditionalColumn>{force}</DefaultAdditionalColumn>
    </EventDetailContainer>
  );
};
