import { EntityAssociation, StateAggregatorSortField } from 'generated/graphql';
import { ColumnsState, ListColumnId, ListItem } from 'types/settings';
import { reverseRecord } from 'utils';

export const columnIdToSortFieldMap: Record<ListColumnId, StateAggregatorSortField> = {
  batteryCharge: StateAggregatorSortField.BatteryVoltage,
  batteryHealth: StateAggregatorSortField.BatteryHealthState,
  batteryStatus: StateAggregatorSortField.LatestBatteryNotification,
  dashboardLights: StateAggregatorSortField.ActiveDashboardLightCount,
  deviceStatus: StateAggregatorSortField.DeviceStatus,
  distanceDriven: StateAggregatorSortField.TotalDistanceDriven,
  driverName: StateAggregatorSortField.DriverName,
  dtcPending: StateAggregatorSortField.DtcPending,
  dtcPermanent: StateAggregatorSortField.DtcPermanent,
  dtcStored: StateAggregatorSortField.DtcStored,
  drivingTime: StateAggregatorSortField.TotalDistanceDriven,
  ecoScore: StateAggregatorSortField.EcoScore,
  fleets: StateAggregatorSortField.Fleet,
  fuelConsumption: StateAggregatorSortField.FuelConsumption,
  fuelEfficiency: StateAggregatorSortField.FuelEfficiency,
  idleTime: StateAggregatorSortField.TotalIdleTime,
  impactStatus: StateAggregatorSortField.NumVehicleImpacts,
  licencePlate: StateAggregatorSortField.VehicleLicencePlate,
  longIdlingEventCount: StateAggregatorSortField.NumDriverLongIdlingEvents,
  make: StateAggregatorSortField.VehicleMake,
  milStatus: StateAggregatorSortField.MilStatus,
  tripCount: StateAggregatorSortField.NumTrips,
  model: StateAggregatorSortField.VehicleModel,
};

export const sortFieldMapToVehicleColumnId = reverseRecord(columnIdToSortFieldMap);

export const entityAssociationColumnMap: Record<EntityAssociation, ColumnsState> = {
  [EntityAssociation.UnassignedDrivers]: {
    disabledColumns: ['licencePlate', 'make', 'model', 'deviceStatus'],
    enforcedColumns: ['driverName'],
  },
  [EntityAssociation.UnassignedVehicles]: {
    disabledColumns: ['driverName'],
    enforcedColumns: ['licencePlate'],
  },
  [EntityAssociation.DriversAndVehicles]: {
    disabledColumns: [],
    enforcedColumns: [],
  },
  [EntityAssociation.AllDrivers]: {
    disabledColumns: [],
    enforcedColumns: [],
  },
  [EntityAssociation.AllVehicles]: {
    disabledColumns: [],
    enforcedColumns: [],
  },
};

export const listUniqueValueColumns: [ListColumnId, (x: ListItem) => string | null | undefined][] = [
  ['make', (x) => x.vehicle?.make],
  ['model', (x) => x.vehicle?.model],
  ['driverName', (x) => x.driver?.name],
];

export const listMinMaxValueColumns: [ListColumnId, (x: ListItem) => number | null | undefined][] = [
  ['distanceDriven', (x) => x.tripStatistics?.totalDistanceDriven ?? 0],
];
