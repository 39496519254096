import { Fragment, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import FleetHierarchy from 'components/Fleet/FleetList';
import SubfleetSelector from 'components/FleetSelector/SubfleetSelector';
import useAccessibleFleetMap, { useTopLevelFleetIds } from 'components/User/useAccessibleFleetMap';
import useRole from 'components/User/useRole';
import { SelectOption } from 'types';
import { FleetHierarchy as FleetHierarchyType } from 'types/fleet';

export type FleetSelectorProps = {
  selectedFleetId?: string;
  title?: string;
  onSelect: (item: SelectOption<string>) => void;
} & (
  | { readonly: true; editable?: false; editableInEditMode?: false }
  | { readonly?: false; editable: true; editableInEditMode?: false }
  | { readonly?: false; editable?: false; editableInEditMode: true }
);

const FleetSelector = ({ selectedFleetId, editable, editableInEditMode, onSelect, title }: FleetSelectorProps) => {
  const i18nContext = useContext(I18nContext);
  const { tSafe } = i18nContext ?? {};
  const fleetMap = useAccessibleFleetMap();
  const { isManager } = useRole();
  const [isInEditMode, setIsInEditMode] = useState(editable); // do we need this if it's never used?
  const { closeModal, props: modalProps } = useModal({
    title: title ?? (tSafe && tSafe('components.FleetSelector.title', { defaultValue: 'Select Fleet' })) ?? '',
  });

  const topLevelFleetIds = useTopLevelFleetIds();

  if (!tSafe) return null;

  const thisSelectedFleet = fleetMap[selectedFleetId ?? ''];

  let currentFleet = thisSelectedFleet;
  const path: FleetHierarchyType[] = [];
  while (currentFleet) {
    path.push(currentFleet);
    currentFleet = fleetMap[currentFleet.parentId ?? ''];
  }

  currentFleet = thisSelectedFleet;

  return (
    <div className="flex">
      {path.reverse().map((fleet) => {
        const parent = fleetMap[fleet.parentId ?? ''];

        return (
          <Fragment key={fleet.id}>
            <SubfleetSelector
              fleets={(parent?.childrenIds ?? topLevelFleetIds).map((id) => fleetMap[id]!)}
              selectedFleet={fleet}
              onSelect={onSelect}
              parentFleetId={parent?.id}
              disabled={!isInEditMode}
              showSeparator={!!parent}
            />
          </Fragment>
        );
      })}

      {!isInEditMode || !currentFleet?.childrenIds?.length ? null : (
        <SubfleetSelector
          fleets={currentFleet.childrenIds.map((id) => fleetMap[id]!)}
          parentFleetId={currentFleet.id!}
          onSelect={onSelect}
          disabled={!isInEditMode}
        />
      )}

      {isManager && !isInEditMode && editableInEditMode && (
        <Modal
          {...modalProps}
          trigger={
            <Tooltip text={tSafe('components.FleetSelector.reassign-fleet', { defaultValue: 'Re-assign fleet' })}>
              <IonIcon name="pencil" className="text-sm cursor-pointer" />
            </Tooltip>
          }
          contentClassName="w-[95vw] h-[95vh]"
        >
          <FleetHierarchy
            onFleetSelect={(fleetId) => {
              onSelect({
                value: fleetId,
                label: tSafe('components.FleetSelector.selected-fleet-id', { defaultValue: 'Selected Fleet ID' }),
              });
              closeModal();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default FleetSelector;
