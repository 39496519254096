import { format, parseISO } from 'date-fns';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { dtcIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import {
  DateTimeColumn,
  DtcAdditionalColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
} from 'components/ActivityHistoryList/styledComponents';
import { DtcEvent, DtcStatus } from 'generated/graphql';
import { cx } from 'utils';

interface DtcEventRowItemProps {
  event: Partial<DtcEvent>;
  time: string;
}

export const DtcEventRowItem = ({ event, time }: DtcEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dtcStatusDescriptionMap, dtcEventTypeDescriptionMap },
      domain: {
        dtc: { dtc_text },
      },
    },
  } = i18nContext;

  const { dtcType, description, status } = event;

  return (
    <EventDetailContainer>
      <DateTimeColumn>{format(parseISO(time), 'iii PPp')}</DateTimeColumn>

      <EventTypeColumn>{`${dtc_text} ${dtcType ? dtcEventTypeDescriptionMap[dtcType] : null}`}</EventTypeColumn>

      <IconColumn>
        <Icon
          name={dtcIconMap[dtcType!]}
          className={cx('!w-4', status === DtcStatus.Active ? 'text-error' : 'text-success')}
        />
      </IconColumn>

      <DtcAdditionalColumn>{`${description} (Status: ${
        status ? dtcStatusDescriptionMap[status] : null
      })`}</DtcAdditionalColumn>
    </EventDetailContainer>
  );
};
